angular
    .module('MyApp')
    .service('CalendarioNpjService', ['$resource', '$q', 'Configs', function($resource, $q, Configs) {

      this.listarEventosAluno = function(codPessoaAluno, unidadeFisica, equipe, papel, participante, tipo, numCaso){
        let deferr = $q.defer();
        var url = Configs.getApiServer() + '/npj/eventos?pessoaLogada=:codPessoaAluno';
        if(unidadeFisica != undefined) {
          url += '&unidadeFisica=:unidadeFisica';
        }
        if(equipe != undefined) {
          url += '&equipe=:equipe';
        }
        if(papel != undefined) {
          url += '&papel=:papel';
        }
        if(participante != undefined) {
          url += '&participante=:participante';
        }
        if(tipo != undefined) {
          url += '&tipo=:tipo';
        }
        if(numCaso != undefined) {
          url += '&numCaso=:numCaso';
        }

        if(codPessoaAluno) {
          $resource(url,
          {}, { 'query': { method: 'GET', isArray: true } }).query({
            codPessoaAluno, unidadeFisica, equipe, papel, participante, tipo, numCaso
          }, function (response) {
            deferr.resolve(response);

          }, function(){
              deferr.reject();
          });
        }else {
                deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.listarEventosDia = function(codPessoaAluno, dataInicio) {
        let deferr = $q.defer();
          $resource(Configs.getApiServer() + '/npj/eventos?pessoaLogada=:codPessoaAluno&dataInicio=:dataInicio',
              {}, { 'query': { method: 'GET', isArray: true } }).query({
                codPessoaAluno, dataInicio
              }, function (response) {
                deferr.resolve(response);

              }, function(){
                  deferr.reject();
              });
          return deferr.promise;
      }

      this.listarEquipes = function(){
        let deferr = $q.defer();
            $resource(Configs.getApiServer() + '/npj/equipes',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });

        return deferr.promise;
      }

      this.listarParticipantesEquipe = function(){
        let deferr = $q.defer();
            $resource(Configs.getApiServer() + '/npj/participantes-equipe',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        return deferr.promise;
      }

      this.obterModelosDoc = function() {
        let deferr = $q.defer();
           $resource(Configs.getApiServer() + '/npj/modelos-tempestividade',
               {}, { 'query': { method: 'GET', isArray: true } }).query({
               }, function (response) {
                 deferr.resolve(response);

               }, function(){
                   deferr.reject();
               });
       return deferr.promise;
      }

    }]);
