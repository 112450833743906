 angular
    .module('MyApp')
    .service('AlunoBoletimV2Service', ['$http', '$resource', '$q', 'SessionData', 'Configs', function($http, $resource, $q, SessionData, Configs) {
      this.getDisciplinas = function(codPessoa, codAluno) {
        let defer = $q.defer();

        $http({
          url: Configs.getApiServer() + `/pessoas/${codPessoa}/alunos/${codAluno}/boletim`
        })
          .success(function(retorno){
            defer.resolve(retorno);
          })
          .error(function(erro){
            defer.reject(erro);
          });

          return defer.promise;
        }

        this.getBoletim = function(codPessoa, codAluno) {
          let defer = $q.defer();

          $http({
            url: Configs.getApiServer() + `/pessoas/${codPessoa}/alunos/${codAluno}/boletim`
          })
            .success(function(retorno){
              defer.resolve(retorno);
            })
            .error(function(erro){
              defer.reject(erro);
            });
            return defer.promise;
        }

        this.getProvasDisciplina = function(codAluno, codDisciplina, codTurma, ano, periodo) {
         let defer = $q.defer();

          $http({
            url: Configs.getApiServer() + `/pessoas/alunos/${codAluno}/disciplina/${codDisciplina}/turma/${codTurma}/ano/${ano}/periodo/${periodo}/provasBoletim`
          })
            .success(function(retorno){
              defer.resolve(retorno);
            })
            .error(function(erro){
              defer.reject(erro);
            });
            return defer.promise;
        }

        this.getHistograma = function(ano, codAluno, codDisciplina, codProva, codTurma, semestre) {
          let defer = $q.defer();
          let params = {
            ano: ano,
            cod_aluno: codAluno,
            cod_disciplina: codDisciplina,
            cod_prova: codProva,
            cod_turma: encodeURI(codTurma),
            semestre: semestre
          };

          if (params) {
              $resource(Configs.getApiServer() +  '/pessoas/histograma', params, {
                      cache: false,
                      query: {
                          method: 'GET'
                      }
                  })
                  .query(params, function(result) {
                      defer.resolve(result);
                  }, function(err) {
                      defer.reject(err);
                  });
          } else {
              defer.reject({
                  _msgErro
              });
          }
          return defer.promise;
        }

        /**
         * Endpoint relativo a detalhes das notas / Histograma
         */
        // this.getHistograma = function(ano, codAluno, codDisciplina, codProva, codTurma, semestre) {
        //   let defer = $q.defer();

        //   $http({
        //     url: Configs.getApiServer() + `/pessoas/histograma?ano=${ano}&cod_aluno=${codAluno}&cod_disciplina=${codDisciplina}&cod_prova=${codProva}&cod_turma=${codTurma}&semestre=${semestre}`
        //   })
        //     .success(function(retorno){
        //       defer.resolve(retorno);
        //     })
        //     .error(function(erro){
        //       defer.reject(erro);
        //     });
        //     return defer.promise;
        // }

        /**
         * Endpoint relativo a detalhes de faltas
         */
        this.getFrequencia = function(ano, codAluno, codPessoa, codDisciplina, codTurma, semestre){
          let defer = $q.defer();
          
          $http.pendingRequests.forEach(function(request) {
              if (request.cancel && request.data.codDisciplina !== codDisciplina) {
                request.cancel.resolve();
              }
          });
          
          $http({
            method: 'POST',
            url: Configs.getApiServer() + `/pessoas/lista_detalhes_frequencia`,
            timeout: defer.promise,
            cancel: defer,
            data: {
              ano: ano,
              codAluno: codAluno,
              codDisciplina: codDisciplina,
              codPessoa: codPessoa,
              codTurma:  encodeURI(codTurma),
              semestre: semestre,
            }
          })
          .success(function(retorno){
            defer.resolve(retorno);
          })
          .error(function(erro){
            defer.reject(erro);
          });
          return defer.promise;
        }

        /**
         * ÚLTIMAS NOTAS
         */

        this.getUltimasNotas = function(codPessoa, codAluno, qtdNotas){
          let defer = $q.defer();
          $http({url: Configs.getApiServer() + `/pessoas/${codPessoa}/alunos/${codAluno}/qtddNotas/${qtdNotas}/ultimasNotas`})
          .success(function(retorno){
            defer.resolve(retorno);
          })
          .error(function(erro){
            defer.reject(erro);
          });
          return defer.promise;
        };

        /** Ordena provas de acordo com o campo ordemProva */
        this.ordenaProvas = function(provas){
          if(provas !== undefined){
          let provasOrdenadas = provas.sort((p1, p2) => {
            return p1.ordemProva - p2.ordemProva;
          });
          return provasOrdenadas;
         }
         return provas
        };

        this.getProvasBoletim = function(codPessoa, codAluno) {
          let defer = $q.defer();

          $http({
            url: Configs.getApiServer() + `/pessoas/${codPessoa}/alunos/${codAluno}/disciplinasBoletim`
          })
          .success(function(retorno){
            defer.resolve(retorno);
          })
          .error(function(erro){
            defer.reject(erro);
          });
          return defer.promise;
        };
    }]);
