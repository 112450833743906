angular.module('MyApp')
    .service('PublicacoesService', ["Configs", "$resource", "$q" , "$http", "DownloadArquivo", function (Configs, $resource, $q, $http, DownloadArquivo) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }

        this.obterInformacoesTurma = function(disciplina = {}) {

            let deferr = $q.defer();

            let objQuery   = {
                ano : disciplina.ano,
                codDisciplina: disciplina.codDisciplina,
                semestre: disciplina.semestre,
                turma: disciplina.turma,

            };

            $resource(Configs.getApiServer() + '/matricula/obter-informacoes-turma', {}, methodGetObject)
            .query(objQuery, function (response) {
                deferr.resolve(response);
            }, function (response) {
                deferr.reject();
            });


            return deferr.promise;
        }

        this.obterPublicacoes = function(disciplina = {}, aluno = {}, tipo = 'docente') {
            let deferr = $q.defer();

            let objQuery   = {
                ano : disciplina.ano,
                codAluno: aluno.codAluno,
                codDisciplina: disciplina.codDisciplina,
                semestre: disciplina.semestre,
                tipo : tipo,
                turma: disciplina.turma,

            };

            if (aluno.codAluno && aluno.id) {
                $resource(Configs.getApiServer() + '/matricula/obter-lista-publicacoes', {}, methodGetArray)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.downloadPublicacao = function (idPublicacao, tipo, alunoId, tipoArquivo) {

            let params = {};
            params.url = Configs.getApiServer() + '/matricula/idPublicacao/' + idPublicacao + '/tipo/' + tipo + '/download-publicacao';
            params.method = "GET";
            params.alunoPublicacao = alunoId;
            params.tipoArquivo = tipoArquivo;
            DownloadArquivo.downloadArquivo(params);
        }

    }]);
