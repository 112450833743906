(function ($app) {

  
  /**
	 * Em todo elemento que possuir o atibuto as-date será aplicado o componente
	 * Datetimepicker (http://eonasdan.github.io/bootstrap-datetimepicker/)
	 * 
	 * O componente se adequa de acordo com o formato, definido através do
	 * atributo format espeficado no elemento. Para data simples use
	 * format="DD/MMM/YYYY", para data e hora use format="DD/MM/YYYY HH:mm:ss"
	 * 
	 * @see http://eonasdan.github.io/bootstrap-datetimepicker/
	 */


  app.directive('validFile',function(){
    return {
      require:'ngModel',
      link:function(scope,el,attrs,ngModel){
        // change event is fired when file is selected
        el.bind('change',function(){
          scope.$apply(function(){
            ngModel.$setViewValue(el.val());
            ngModel.$render();
          });
        });
      }
    }
  });
  
  app.directive('numbersOnly', function () {
	    return {
	        require: 'ngModel',
	        link: function (scope, element, attr, ngModelCtrl) {
	            function fromUser(text) {
	                if (text) {
	                    var transformedInput = text.replace(/[^0-9]/g, '');
	 
	                    if (transformedInput !== text) {
	                        ngModelCtrl.$setViewValue(transformedInput);
	                        ngModelCtrl.$render();
	                    }
	                    return transformedInput;
	                }
	                return undefined;
	            }            
	            ngModelCtrl.$parsers.push(fromUser);
	        }
	    };
  });
  
  app.directive('pwCheck', [function () { 'use strict';
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
      var firstPassword = '#' + attrs.pwCheck;
      elem.add(firstPassword).on('keyup', function () {
        scope.$apply(function () {
        var v = elem.val()===$(firstPassword).val();
        ctrl.$setValidity('pwmatch', v);
        });
      });
      }
    }
  }])

  .directive('onlyNumbers', function() {
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, ngModel) {
            ngModel.$parsers.unshift(function(value) {
                if(value){
                    let hasLetters = value.match(/[0-9]+/g);       
                    let valid = !!hasLetters && hasLetters.length > 0 && hasLetters[0].length == value.length;
                    ngModel.$setValidity('onlyLetters', valid);
                    return valid ? value : undefined;
                }
                return value;
            });

            ngModel.$formatters.unshift(function(value) {
                if(value){
                    let hasLetters = value.match(/[0-9]+/g);
                    let valid = !!hasLetters && hasLetters.length > 0 && hasLetters[0].length == value.length;
                    ngModel.$setValidity('onlyLetters', valid);
                }
                return value;
            });
        }
    };
})

  .directive('milisecsToDate', function() {
    return {
    	restrict: 'A',
        require: '?ngModel',
        link: function(scope, elem, attr, ngModel) {
            // formats text going to user (from model to view)
        	ngModel.$formatters.push(function(v){
        		
        		if(v && !isNaN(v) ){
        			return new Date(v); // programmatically set value will be
										// formatted
        		} else {
        			return
        		}
            });
             // formats text going to user (from model to view)
        	ngModel.$parsers.push(function(v){
             
        	if(v instanceof Date )
        		return v.getTime(); // programmatically set value will be
									// formatted
            });

        }
    };
})

    .directive('disableSideMenu', [
        '$ionicSideMenuDelegate',
        function ($ionicSideMenuDelegate) {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    element.on('touchstart', function () {
                        scope.$apply(function () {
                            $ionicSideMenuDelegate.canDragContent(false);
                        });
                    });

                    element.on('touchend touchcancel', function () {
                        scope.$apply(function () {
                            $ionicSideMenuDelegate.canDragContent(true);
                        });
                    });
                }
            };
        }
    ])

    .directive('creditCardMonth', ['$timeout', function ($timeout){ 
        return {
            require: 'ngModel',
            link: function(scope, elem, attr, ngModel) {
                ngModel.$parsers.unshift(function(value) {
                    let year = scope.cardForm.anoSelected;
                    if(value){
                        let currentMonth = moment().format('MM');
                        let currentYear = moment().format('YYYY');
                        let valid = parseInt(value.valor) >= parseInt(currentMonth);
                        if(!valid && year.$viewValue) {
                        	valid = parseInt(year.$viewValue.descricao) > parseInt(currentYear);
                        }
                        
                        ngModel.$setValidity('month', valid);
                        ngModel.$valid = valid;
                        ngModel.$invalid = !valid;
                        year.$setValidity('year', valid);
                        year.$valid = valid;
                        year.$invalid = !valid;
                        
                    }
                    return value;
                });

                ngModel.$formatters.unshift(function(value) {
                    let year = scope.cardForm.anoSelected;
                    if(value){
                        let currentMonth = moment().format('MM');
                        let currentYear = moment().format('YYYY');
                        let valid = parseInt(value.valor) >= parseInt(currentMonth);
                        if(!valid && year.$viewValue){
                        	valid = parseInt(year.$viewValue.descricao) > parseInt(currentYear);
                        }

                        ngModel.$setValidity('month', valid);
                        ngModel.$valid = valid;
                        ngModel.$invalid = !valid;
                        year.$setValidity('year', valid);
                        year.$valid = valid;
                        year.$invalid = !valid;
                    }
                    return value;
                });
            }
        };
    }])

    .directive('creditCardYear', ['$timeout', function ($timeout){ 
        return {
            require: 'ngModel',
            link: function(scope, elem, attr, ngModel) {
                ngModel.$parsers.unshift(function(value) {
                    let mes = scope.cardForm.mesSelected;
                    if(value && mes.$viewValue){
                        let currentMonth = moment().format('MM');
                        let currentYear = moment().format('YY');

                        let valid = parseInt(mes.$viewValue.valor) >= parseInt(currentMonth);
                        if(!valid) {
                        	valid = parseInt(value.valor) > parseInt(currentYear);
                        }
                         
                         ngModel.$setValidity('year', valid);
                         ngModel.$valid = valid;
                         ngModel.$invalid = !valid;
                         mes.$setValidity('month', valid);
                         mes.$valid = valid;
                         mes.$invalid = !valid;

                    }
                    return value;
                });

                ngModel.$formatters.unshift(function(value) {
                    let mes = scope.cardForm.mesSelected;
                    if(value && mes.$viewValue){
                        let currentMonth = moment().format('MM');
                        let currentYear = moment().format('YY');

                        let valid = parseInt(mes.$viewValue.valor) >= parseInt(currentMonth);
                        if(!valid) {
                        	valid = parseInt(value.valor) > parseInt(currentYear);
                        }
                        
                        ngModel.$setValidity('year', valid);
                        ngModel.$valid = valid;
                        ngModel.$invalid = !valid;
                        mes.$setValidity('month', valid);
                        mes.$valid = valid;
                        mes.$invalid = !valid;
                    }
                    return value;
                });
            }
        };
    }])
    .directive('fingerPrintGetNet',function(){
        return {
            restrict: 'E',
            template:'<div></div>',
            link: function(scope, elem, attr){

                while(document.getElementsByClassName("iframe-getnet").length>0){
                    document.body.removeChild(document.getElementsByClassName("iframe-getnet")[0]);
                }
                
                var iframe= document.createElement('iframe');
                iframe.classList.add("iframe-getnet");
                iframe.src=`https://h.online-metrix.net/fp/tags?org_id=${scope.$eval(attr.orgid)}&session_id=${scope.$eval(attr.sessionid)}`;
                iframe.style="width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;";
                iframe.onload=function(){
                
                    window.setTimeout(function(){
                    
                        document.body.removeChild(iframe);
                        
                    },5000);
                };
                document.body.appendChild(iframe);
            }
        };
    })
	.directive('cssAltoContraste',['UtilService', function(UtilService) {
		return function(scope, el, attrs) {
            //só funciona com a chamada do broadcast(altoContraste)
            scope.$on('altoContraste', function () {

                var cssId   = 'css-alto-contraste';
                var styleId = 'style-alto-contraste';

                // se isAtivoAltoContraste == true ele ativa o css e o style da customização
                if(scope.isAtivoAltoContraste) {

                    // ativa css
                    var cssAtivo = document.getElementById(cssId)
                    if (!cssAtivo) {
                        var head  = document.getElementsByTagName('head')[0];
                        var link  = document.createElement('link');
                        link.id   = cssId;
                        link.rel  = 'stylesheet';
                        link.href = 'css/alto-contraste.css';
                        head.appendChild(link);
                    }

                    // ativa style customizado
                    let paramsObtemRecursoAltoContraste = {
                        aplicacao: 'ALUNO_RESP_ACESSIBILIDADE',
                        objeto: 'CSS_ALTO_CONTRASTE'
                    }

                    UtilService.obtemRecurso(paramsObtemRecursoAltoContraste).then((data) => {
                        if(data.texto != null) {
                            var estiloAtivo = document.getElementById(styleId);
                            if(!estiloAtivo) {
                                var style = document.createElement('style');
                                style.id = styleId;
                                style.innerHTML = data.texto;
                                $("body").append(style);
                            }
                        }
                    });

                } else {
                    //remove o css do dom
                    var cssAtivo = document.getElementById(cssId)
                    if (cssAtivo) {
                        cssAtivo.remove();
                    }

                    //remove o style do dom
                    var estiloAtivo = document.getElementById(styleId);
                    if(estiloAtivo) {
                        estiloAtivo.remove();
                    }
                }
            });
		}
    }])
	.directive('cssCustom', function() {
		return function(scope, el, attrs) {
			scope.cssCustom.then((data) => {
				var style = document.createElement('style');
				style.innerHTML = data.texto;
				$("body").append(style);
			});
		}
    })
	.directive('cssLoginCustom', function() {
		return function(scope, el, attrs) {
			scope.cssLoginCustom.then((data) => {
				var style = document.createElement('style');
				style.innerHTML = data.texto;
				$("body").append(style);
			});
		}
    })
	.directive('logoLoginCustom', function() {
		return function(scope, el, attrs) {
			scope.logoLoginCustom.then((data) => {
                
                // caso não exista uma imagem será usada a imagem disponibilizada no css
                if(data.imagem === null){
                    scope.usaLogoLoginCustom = false;
                    return ;
                }

				var img = document.createElement('img');
				img.src = "data:image/png;base64," + btoa(data.imagem.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(""));
                img.className = "login-logo-custom";
				$("logo-login-custom").append(img);

                scope.usaLogoLoginCustom = true;
			});
		}
    })
	.directive('logoCustom', function() {
		return function(scope, el, attrs) {
			scope.logoCustom.then((data) => {
                
                // caso não exista uma imagem será usada a imagem disponibilizada no css
                if(data.imagem === null){
                    scope.usaLogoCustom = false;
                    return ;
                }

				var img = document.createElement('img');
				img.src = "data:image/png;base64," + btoa(data.imagem.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(""));
                img.className = "side-menu-marca-custom";
				$("logo-custom").append(img);

                scope.usaLogoCustom = true;
			});
		}
    })
	.directive('faviconCustom', function() {
		return function(scope, el, attrs) {
			scope.faviconCustom.then((data) => {
                
                // caso não exista uma imagem será usada a imagem disponibilizada no css
                if(data.imagem === null){
                    scope.usaFaviconCustom = false;
                    return ;
                }

				var img = document.createElement('img');
				img.src = "data:image/png;base64," + btoa(data.imagem.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(""));
                img.className = "toggle-left-side-bar-image-custom";
				$("favicon-custom").append(img);

                scope.usaFaviconCustom = true;
			});
		}
    });
  
} (app));