angular.module('MyApp').service('AORPaginaRedirectService', ["Configs", "SessionData", "$resource", "$q", AORPaginaRedirectService]);


function AORPaginaRedirectService(Configs, SessionData, $resource, $q){
    
    /**
     * Obtém os dados para página de redirecionamento.
     */
    this.obterDadosAORPaginaRedirecionamento = function(codAluno, redirectPageId){
        let defer = $q.defer();

        // Obtém os dados para redirecionamento de página, com base no código do aluno.
        if (codAluno) {
            $resource(Configs.getApiServer() + '/comum/aor-redirect?aluno=:cod_aluno&redirectPageId=:redirect_PageId',
                      {}, { 'query': {method: 'GET' }}).query({
                          cod_aluno: encodeURIComponent(codAluno),
                          redirect_PageId: encodeURIComponent(redirectPageId)
                      }, function(response){
                            if(response.urlDestino){
                                let dadosAORPaginaRedirect = {
                                    'urlDestino': response.urlDestino,
                                    'nomeParametroUrl': response.nomeParametroUrl,
                                    'minutosExpiracaoToken': response.minutosExpiracaoToken,
                                    'dataHoraExpiracaoToken': response.dataHoraExpiracaoToken,
                                    'token': response.token,
                                    'rotaDeRetorno': response.rotaDeRetorno
                                }
                                
                                defer.resolve(dadosAORPaginaRedirect);                                
                            } else {
                                defer.resolve(false);
                            }
                      });
        } else {            
            defer.resolve(false);
        }

        return defer.promise;
    };

}