angular
    .module('MyApp')
    .service('RequeridoService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.buscarRequerido = function(idRequerido){
        let deferr = $q.defer();
         if (idRequerido) {
            $resource(Configs.getApiServer() + '/npj/requerido?idRequerido=:idRequerido',
                {}, { 'query': { method: 'GET', isArray: false } }).query({
                  idRequerido
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

    }]);
