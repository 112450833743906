angular.module('MyApp')
    .service('MatriculaService', ["Configs", "SessionData", "$resource", "$q", "$translate", function (Configs, SessionData, $resource, $q, $translate) {

        // Service designed to verify if user has a contract to accept before accessing the system
        this.verificaExibeContratoLogin = function (codAluno) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:cod_aluno/verifica_exibe_contrato_login',
            {}, {'query': {method: 'GET', isArray: false}}).query({
            cod_aluno: encodeURIComponent(codAluno)
          }, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () { 
            defer.reject();
          });
          return defer.promise;
        };

        // Service designed to verify if user has a LGPD contract to accept before accessing the system
        this.verificaExibeContratoLGPD = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/codPessoa/:codPessoa/codAluno/:codAluno/codResp/:codResp/sistema/:sistema/verifica_deve_aceitar_termo_lgpd',
              {}, {'query': {method: 'GET', isArray: false}}).query(params, function (response) {
              if (!response.status)
                defer.resolve(response);
              else
                defer.reject();
            }, function () { 
              defer.reject();
            });
            return defer.promise;
        };

        this.obterTermoLgpd = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/id_termo/:id_termo/sistema/:sistema/obter_termo_lgpd',
              {}, {'query': {method: 'GET', isArray: false}}).query(params, function (response) {
              if (!response.status)
                defer.resolve(response);
              else
                defer.reject();
            }, function () { 
              defer.reject();
            });
            return defer.promise;
        }

        this.incluirTermoLGPD = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/incluir_termo_lgpd',
                {}, {'query': {method: 'POST', isArray: false}}).query(params, function (response) {
                if (!response.status)
                    defer.resolve(response);
                else
                    defer.reject();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        }

        this.recusarTermoLGPD = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/recusar_termo_lgpd',
                {}, {'query': {method: 'POST', isArray: false}}).query(params, function (response) {
                if (!response.status)
                    defer.resolve(response);
                else
                    defer.reject();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        }

        this.listarTermosLGPDAceitosPessoa = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/codPessoa/:codPessoa/listar_termos_lgpd_aceitos_pessoa',
                {}, {'query': {method: 'GET', isArray: true}}).query(params, function (response) {
                if (!response.status)
                    defer.resolve(response);
                else
                    defer.reject();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        }

        this.obterTermoLGPDAceitoPessoaImagem = function (params) {
            let defer = $q.defer();
            $resource(Configs.getApiServer() + '/lgpd/idDocumentoPessoa/:idDocumentoPessoa/obter_termo_lgpd_aceito_pessoa_imagem',
                {}, {'query': {method: 'GET', isArray: false}}).query(params, function (response) {
                if (!response.status)
                    defer.resolve(response);
                else
                    defer.reject();
            }, function () {
                defer.reject();
            });

            return defer.promise;
        }


        // Service designed to generate / view the user contract
        this.gerarContratoAlunoLogin = function (codAluno) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/cod_pessoalogada/:cod_pessoalogada/cod_aluno/:cod_aluno/gera_contrato_aluno_login',
            {}, {'query': {method: 'GET', isArray: false}}).query({
            cod_aluno: encodeURIComponent(codAluno),
            cod_pessoalogada: encodeURIComponent(SessionData.user.id)
          }, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };

        // Service designed to accept the user contract
        this.aceitarContratoAlunoLogin = function (codAluno, htmlContrato) {
          let payload = {
            aluno: codAluno,
            htmlContrato: htmlContrato,
            contratoAceito: true
          };
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/pessoas/aceita_contrato_aluno_login',
            {}, {'query': {method: 'POST', isArray: false}}).query(payload, function (response) {
            if (!response.status)
              defer.resolve(response);
            else
              defer.reject();
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };

        this.obterContratoImagemAluno = function (id_contrato) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + '/matricula/id_contrato/:id_contrato/busca_contrato_imagem_aluno_by_id_contrato',
            {}, {'query': {method: 'GET', isArray: false}}).query({id_contrato: id_contrato}, function (response) {
            if (!response.status)
              defer.resolve(response)
            else
              defer.reject()
          }, function () {
            defer.reject();
          });
          return defer.promise;
        };


        this.obterConfigMatricula = function (aluno) {
            let deferr = $q.defer();

            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/obterConfigMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.obterDadosQuadroHorario = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let codAluno = encodeURIComponent(aluno.codAluno);
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + codAluno + '/obterDadosQuadroHorario',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(config,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.obterDisciplinasMatriculadas = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let codAluno = encodeURIComponent(aluno.codAluno);
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + codAluno + '/obterDisciplinasMatriculadas',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(config,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.removerDisciplinasQuadro = function (params) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/remover-disciplinas',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(params,
                    function (response) {
                        if (response)
                            deferr.resolve(response);
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.termoMatricula = function () {
            let deferr = $q.defer();
            var aluno = SessionData.aluno.list[SessionData.aluno.selecionado];
            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:alunoId/executaEPAlteraInicioMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        alunoId: aluno.codAluno
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.aceitoTermo = function (resposta) {
            let deferr = $q.defer();
            var aluno = SessionData.aluno.list[SessionData.aluno.selecionado];
            if (aluno) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:alunoId/respostaAluno/:respostaAluno/executaEPAcaoInicioMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        alunoId: aluno.codAluno,
                        respostaAluno: resposta ? 'S' : 'N'
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.existeContratoAceito = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/ano/:ano/periodo/:periodo/existeContratoAceito',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: aluno.codAluno,
                        ano: configMatricula.ano,
                        periodo: configMatricula.periodo
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.gerarContratoAlunoRollback = function (params) {
            let deferr = $q.defer();

            if (params) {
                $resource(Configs.getApiServer() + '/matricula/postGerarContratoAlunoRollback',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function() {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }

            return deferr.promise;
        }

        this.buscaContratoImagemAluno = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/ano/:ano/periodo/:periodo/buscaContratoImagemAluno',
                    {}, { 'query': { method: 'GET', isArray: false } }).query({
                        codAluno: aluno.codAluno,
                        ano: configMatricula.ano,
                        periodo: configMatricula.periodo
                    }, function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject()
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }

        this.aceitarContratoAluno = function (aceito, configMatricula, contrato, aluno) {
            let deferr = $q.defer();

            if (configMatricula && contrato && aluno) {
                let params = {
                    codAluno: aluno.codAluno,
                    htmlContrato: contrato.htmlContrato,
                    contratoAceito: aceito,
                    presencial: false,
                    ehContratoEmAjuste: false,
                    configMatricula: configMatricula
                }

                $resource(Configs.getApiServer() + '/matricula/aceitarContratoAluno',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.reject();
            }
            return deferr.promise;
        }
        
        this.obterRequerimentoAluno = function (aluno, config) {
            let deferr = $q.defer();

            if (aluno && config) {
                let params = {
                    codAluno: aluno.codAluno,
                    ano: config.ano,
                    periodo: config.periodo,
                    tipoMatricula: config.tipomatricula,
                    secretaria: false
                };

                $resource(Configs.getApiServer() + '/matricula/obterRequerimentoMatriculaAluno',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }

        this.gerarBoletoMatricula = function (aluno, configMatricula) {
            let deferr = $q.defer();

            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/' + aluno.codAluno + '/gerarBoletoMatricula').save(configMatricula,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }
        
        this.verificarImpressaoBoleto = function (aluno, configMatricula, boleto) {
            let deferr = $q.defer();
            params = {
                codAluno: aluno.codAluno,
                codBoleto: boleto
              };
            if (aluno && configMatricula) {
                $resource(Configs.getApiServer() + '/matricula/codAluno/:codAluno/boleto/:codBoleto/verificarSeImprimeBoletoWebMatricula',
                    {}, { 'query': { method: 'GET', isArray: false } })
                    .query(params, function(result) {
                        deferr.resolve(result);
                    }, function(err) {
                        deferr.reject(err);
                    });
                } 
                    return deferr.promise;
        }

        this.downloadBoleto = function (aluno, codBoleto = 0) {
            let defer = $q.defer();
            if (aluno) {
                let _objAluno = {
                    codPessoa : aluno.user.id,
                    codAluno  : aluno.codAluno,
                    codBoleto : codBoleto
                };
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/codAluno/:codAluno/codBoleto/:codBoleto/exibirArquivo/false/obterBoleto', {}, {
                    cache: false,
                    get: {
                        responseType: 'arraybuffer',
                    transformResponse: function (data, headersGetter, status) {
                            if(status == 500) {
                                defer.reject();
                                var decoder = new TextDecoder("utf-8");
                                var domString = decoder.decode(data);
                                var json = JSON.parse(domString);
                                return json
                            }

                            let pdf;
                            if (data) {
                                pdf = new Blob([data], {
                                    type: 'application/pdf'
                                });
                            }
                            return {
                                pdf: pdf
                            };
                        }
                    }
                }).get(_objAluno, function (result) {
                        delete _objAluno.codBoleto;
                        defer.resolve(result);
                    }, function (error) {
                        delete _objAluno.codBoleto;
                        defer.reject(error);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;
        }
        
        this.obterDadosPagarMatriculaCartao = function (aluno, codBoleto){
            let deferr = $q.defer();
            
            if (aluno && codBoleto){
                let _objAluno = {
                    codPessoa : aluno.user.id,
                    codAluno  : aluno.codAluno,
                    codBoleto : codBoleto
                };
                $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:codAluno/cod_pessoa/:codPessoa/id_boleto/:codBoleto/obter_dados_pagar_matricula_com_cartao',
                    {}, { 'query': { method: 'GET', isArray: false } }).query(_objAluno,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }

        this.validarPremissaoCartaoMatricula = function(cod_aluno, id_boleto) {
          let defer = $q.defer();
          params = {
              cod_aluno: cod_aluno,
              id_boleto: id_boleto
            };
          $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/id_boleto/:id_boleto/permite_pagar_matricula_com_cartao', {}, {
              cache: false,
              query: {
                  method: 'GET'
              }
          })
          .query(params, function(result) {
              defer.resolve(result);
          }, function(err) {
              defer.reject(err);
          });

          return defer.promise;
        }

        /* Endpoint para obter as permissões de ações do aluno na tela de disciplina
            ex: adicionar ou remover.
        */
        this.checarOperacoesPermitidas = function(cod_aluno) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno
            }
            
            if (cod_aluno) {
                $resource(Configs.getApiServer() + '/matricula/checar-operacoes-permitidas', {}, {
                    cache: false,                    
                    query: {
                        method: 'GET', 
                        isArray: false
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });
      
                return defer.promise;
            }            
          }

        /* Endpoint para obter os tipos de disciplinas na tela de adicionar. */
        this.listarTiposDisciplinas = function(cod_aluno) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno
            }
            
            if (cod_aluno) {
                $resource(Configs.getApiServer() + '/matricula/listar-tipos-disciplinas-matricula', {}, {
                    cache: false,                    
                    query: {
                        method: 'GET', 
                        isArray: true
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });
      
                return defer.promise;
            }            
          }

          /* Endpoint para obter os cursos para preenchimento de combo. */
        this.listarCursosMatricula = function() {
            let defer = $q.defer();

            $resource(Configs.getApiServer() + '/matricula/listar-cursos-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query({}, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;
                        
          }

          this.listarAreas = function(cod_aluno) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno
            }            
            
            $resource(Configs.getApiServer() + '/matricula/listar-areas-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;            
          }

          this.listarGruposPorArea = function(cod_aluno, area) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno,
                area: area
            }
            $resource(Configs.getApiServer() + '/matricula/listar-grupos-area-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;            
          }

          this.listarGruposMatricula = function(cod_aluno, obrigatorio) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno,
                obrigatorio: obrigatorio
            }
                        
            $resource(Configs.getApiServer() + '/matricula/listar-grupos-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;
                        
          }

          this.listarSimNao = function() {
            let defer = $q.defer();            
            $resource(Configs.getApiServer() + '/matricula/listar-sim-nao', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query({}, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;
          }

          this.listarTurnosMatricula = function(cod_aluno) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno
            }
            
            $resource(Configs.getApiServer() + '/matricula/listar-turnos-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;            
          }

          this.listarUnidadesFisicasMatricula = function(cod_aluno) {
            let defer = $q.defer();

            let params = {
                codAluno: cod_aluno
            }
            
            $resource(Configs.getApiServer() + '/matricula/listar-unidades-fisicas-matricula', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;            
          }

          this.listarDisciplinas = function(matriculaTD){
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/listar-disciplinas',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          this.listarTurmas = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/listar-turmas',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          this.listarTurmasTroca = function(params) {
            let defer = $q.defer();
                        
            $resource(Configs.getApiServer() + '/matricula/listar-turmas-troca', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;
          }

          this.listarTurmasDisciplinasEquivalentesTroca = function(params) {
            let defer = $q.defer();
                        
            $resource(Configs.getApiServer() + '/matricula/listar-turmas-disciplinas-equivalentes-troca', {}, {
                cache: false,                    
                query: {
                    method: 'GET', 
                    isArray: true
                }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
    
            return defer.promise;
          }

          /* Método para incluir as disciplinas desejadas no quadro de matrícula. */
          this.incluirDisciplinasMatricula = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/incluir-disciplinas-matricula',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          } 

          /* Método para realizar troca de turma. */
          this.trocarTurmaMatricula = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/trocar-turma',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para realizar troca de turma disciplina por uma equivalente. */
          this.trocarTurmaDisciplinaEquivalente = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/trocar-turma-disciplina-equivalente',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para realizar troca de turma disciplina por uma equivalente. */
          this.listarValoresSimulacao = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/listar-valores-simulacao',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }
          
          /* Método para realizar confirmacao da matricula. */
          this.confirmarMatricula = function(matriculaTD) {
            let deferr = $q.defer();

            if (matriculaTD) {                
                $resource(Configs.getApiServer() + '/matricula/confirmar-matricula',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(matriculaTD,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para buscar opções de disciplinas na priorizacao. */
          this.listarOpcoesDisciplina = function(params, disciplinas) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/:codDisciplina/opcoes-disciplina',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(params, disciplinas,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para buscar opções de turmas na priorizacao. */
          this.listarOpcoesTurma = function(params) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/:codAluno/:codDisciplina/opcoes-turma',
                    {}, { 'query': { method: 'GET', isArray: true } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para incluir a opção de turma na priorizacao. */
          this.incluirOpcao = function(turmaOpcaoDTO) {
            let deferr = $q.defer();

            if (turmaOpcaoDTO) {                
                $resource(Configs.getApiServer() + '/matricula/opcoes-turma',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(turmaOpcaoDTO,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para remover a opção de turma na priorizacao. */
          this.removerOpcao = function(turmaOpcaoDTO) {
            let deferr = $q.defer();

            if (turmaOpcaoDTO) {                
                $resource(Configs.getApiServer() + '/matricula/remover-opcao-turma',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(turmaOpcaoDTO,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para listar Disciplinas na priorizacao. */
          this.listarDisciplinasPriorizacao = function(disciplinasPriorizacaoDTO) {
            let deferr = $q.defer();

            if (disciplinasPriorizacaoDTO) {                
                $resource(Configs.getApiServer() + '/matricula/disciplinas-priorizacao',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(disciplinasPriorizacaoDTO,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para listar Disciplinas na priorizacao. */
          this.subirDescerPrioridade = function(params, disciplinasPriorizacaoDTO) {
            let deferr = $q.defer();

            if (disciplinasPriorizacaoDTO) {                
                $resource(Configs.getApiServer() + '/matricula/:acao/prioridade',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(params, disciplinasPriorizacaoDTO,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para obter lista de responsáveis financeiros na matricula. */
          this.obterResponsaveisFinanceirosMatricula = function(cod_aluno) {
            let deferr = $q.defer();

            let params = {
                codAluno: cod_aluno
            }

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/listar-responsaveis-financeiros-matricula',
                    {}, { 'query': { method: 'GET', isArray: true } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para obter dados do responsavel financeiro. */
          this.obterDadosPessoaisResponsavelFinanceiro = function(params) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/obter-dados-pessoais-responsavel-financeiro',
                    {}, { 'query': { method: 'GET', isArray: false } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

          /* Método para obter dados do responsavel financeiro. */
          this.alterarResponsavelFinanceiro = function(aluno, params) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/alterar-dados-responsavel-financeiro',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(aluno, params,
                    function (response) {
                    if (!response.status)
                        deferr.resolve(response);
                    else
                        deferr.reject();
                    }, function () {
                        deferr.reject();
                    });
                    return deferr.promise;
            }
        }

          /* Método para obter dados da autorizacao Matricula. */
          this.obterAutorizacaoMatricula = function(params) {
            let deferr = $q.defer();

            if (params) {                
                $resource(Configs.getApiServer() + '/matricula/autorizacao-matricula',
                    {}, { 'query': { method: 'GET', isArray: true } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
          }

        /* Método para realizar confirmacao da autorizacao da matricula. */
        this.confirmarAutorizacao = function(autorizacao) {
            let deferr = $q.defer();

            if (autorizacao) {         
                $resource(Configs.getApiServer() + '/matricula/autorizacao-matricula',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(autorizacao,
                    function (response) {
                        if (!response.status)
                            deferr.resolve(response)
                        else
                            deferr.reject();
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }
                    
          
    }]);
