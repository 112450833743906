angular
    .module('acordoConsulta')
    .controller('AcordoConsultaController', [
        '$scope',
        '$rootScope',
        '$state',
        '$timeout',
        '$translate',
        'Notification',
        '$ionicHistory',
  		'PagamentoCartaoFactory',
        '$ionicModal',
  		'CartaoService',
        '$log',
        'SessionData',
        'AcordoService',
        '$ionicLoading',
        '$stateParams',
        'AcordoFactory',
        'Configs',
        'DownloadArquivo',
        'FileSaver',
        function ($scope, $rootScope, $state, $timeout, $translate, Notification, $ionicHistory, PagamentoCartaoFactory, $ionicModal, CartaoService, $log, SessionData, AcordoService, $ionicLoading, $stateParams, AcordoFactory, Configs, DownloadArquivo, FileSaver) {
            var acordoConCtrl = this;
            acordoConCtrl.id = 'AcordoConsultaController';
            $scope.id = acordoConCtrl.id;

            function showLoading(){
                $ionicLoading.show({
                    content: 'Loading',
                    animation: 'fade-in',
                    showBackdrop: true,
                    maxWidth: 200,
                    showDelay: 0
                });
            } 
            showLoading();

            acordoConCtrl.sessionData = SessionData;
            acordoConCtrl.aluno = acordoConCtrl.sessionData.aluno;
            acordoConCtrl.user = acordoConCtrl.sessionData.user;
            acordoConCtrl.responsavel = SessionData.perfil === "responsavel" ? true : false;
            acordoConCtrl.realizarAcordo = false;
            acordoConCtrl.qtdAcordos = 0;
            acordoConCtrl.qtdCobranca = 0;
            acordoConCtrl.qtdParcelas = 0;
            acordoConCtrl.dados = [];
            acordoConCtrl.getWindowWidth = getWindowWidth;
            acordoConCtrl.openDetail = openDetail;
            acordoConCtrl.closeDetail = closeDetail;
            acordoConCtrl.obterDetalhes = obterDetalhes;
            acordoConCtrl.downloadBoleto = downloadBoleto;
            acordoConCtrl.getResource = getResource;
            acordoConCtrl.title = 'Home.view.Acordo';
            acordoConCtrl.text = $translate.instant('Home.view.Acordo');
            acordoConCtrl.textTitle = $translate.instant('Home.view.Acordos');

            AcordoService.obterListaAcordos()
                .then(function (result) {
                    $ionicLoading.hide();
                    acordoConCtrl.dados = result.dados;
                    acordoConCtrl.qtdAcordos = result.quantidade;

                    if ($stateParams.codAcordo) {
                        acordoConCtrl.obterDetalhes(parseInt($stateParams.codAcordo));
                    }
                }, function(err){
                    $ionicLoading.hide();
                });
            
            function getResource(abstractResource) {
                return AcordoFactory.opcoesDoAcordo.usaPreAcordo ? 'Preacordo.' + abstractResource : 'Acordo.' + abstractResource;
            }

            function getWindowWidth() {
                return window.innerWidth;
            }

            function openDetail(codAcordo) {
                acordoConCtrl.obterDetalhes(codAcordo);
            }

            function closeDetail() {
                acordoConCtrl.adordo = {};
                acordoConCtrl.modal.hide();
                acordoConCtrl.modalPreAcordo.hide();

                if ($stateParams.codAcordo) {
                    $state.go('home.acordo.consulta');
                }
            }
            
            function obterDetalhes(codAcordo) {
                showLoading();
                
                if ($scope.$parent.acordoCtrl.opcoesAcordo && $scope.$parent.acordoCtrl.opcoesAcordo.usaPreAcordo) {
                    AcordoService
                        .obterDetalhesPreAcordo(codAcordo)
                        .then(function (result) {
                            if (result) {
                                acordoConCtrl.adordo = result;
                                acordoConCtrl.adordo.numAcordo = result.PRE_ACORDO;
    
                                AcordoService
                                    .obterCobrancasDoPreAcordo(codAcordo)
                                    .then(function (resultCobranca) {
                                        acordoConCtrl.adordo.listaDeCobrancaEmAberto = resultCobranca.dados;
                                        acordoConCtrl.qtdCobranca = resultCobranca.quantidade;
    
                                        AcordoService
                                            .obterCobrancasGeradasPeloPreAcordo(codAcordo)
                                            .then(function (resultParcela) {
                                                acordoConCtrl.qtdParcelas = resultParcela.quantidade;
                                                acordoConCtrl.adordo.planoDePagamentoSelecionado = resultParcela.dados;
                                                acordoConCtrl.modalPreAcordo.show();
                                                $ionicLoading.hide();
                                            }).catch(function(err) {
                                                $ionicLoading.hide();
                                                defer.resolve('');
                                            });
    
                                    }).catch(function(err) {
                                        $ionicLoading.hide();
                                        defer.resolve('');
                                    });
                            }
                        }).catch(function(err) {
                            $ionicLoading.hide();
                            defer.resolve('');
                        });
                } else {
                    AcordoService
                        .obterDetalhesAcordo(codAcordo)
                        .then(function (result) {
                            if (result.dados) {
                                acordoConCtrl.adordo = result.dados[0];

                                AcordoService
                                    .obterCobrancasDoAcordo(codAcordo)
                                    .then(function (resultCobranca) {
                                        acordoConCtrl.adordo.listaDeCobrancaEmAberto = resultCobranca.dados;
                                        acordoConCtrl.qtdCobranca = resultCobranca.quantidade;

                                        AcordoService
                                            .obterCobrancasGeradasPeloAcordo(codAcordo)
                                            .then(function (resultParcela) {
                                                acordoConCtrl.qtdParcelas = resultParcela.quantidade;
                                                acordoConCtrl.adordo.planoDePagamentoSelecionado = resultParcela.dados;
                                                acordoConCtrl.modal.show();
                                                $ionicLoading.hide();
                                            }).catch(function(err) {
                                                $ionicLoading.hide();
                                                defer.resolve('');
                                            });
                                    }).catch(function(err) {
                                        $ionicLoading.hide();
                                        defer.resolve('');
                                    });
                            }
                        }).catch(function(err) {
                            $ionicLoading.hide();
                            defer.resolve('');
                        });
                }
            }

            function downloadBoleto(codBoleto) {
                $ionicLoading.show({
                    content: 'Loading',
                    animation: 'fade-in',
                    showBackdrop: true,
                    maxWidth: 200,
                    showDelay: 1
                });
                
                AcordoService.downloadBoleto(codBoleto);
            }
          
            acordoConCtrl.pagamento = async function(parcela){
                
                let retorno = await obterDadosPagamento(parcela);

                let cobrancas = {
                    valorDocumento: retorno.cartaoValorDocumento,
                    origem: retorno.cartaoOrigem,
                    dataCalculoValor: retorno.cartaoDataCalculoValor,
                    codResponsavelFinanceiro: retorno.cartaoCodRespFinan,
                    opcaoPgtoCartao: retorno.cartaoOpcaoPgto,
                    cobrancasConcatenadas: retorno.cartaoCobrancasSelecionadas,
                    cartaoItensCobrancas: retorno.cartaoItensCobrancas,
                    valorSelecionado: retorno.cartaoValorOriginalDocumento,
                    encargoCalculado: retorno.cartaoEncargoDocumento,
                    encargoBolsa: retorno.cartaoEncargoBolsaDocumento,
                    descontoAdiantamentoCalculado: retorno.cartaoDescontoDocumento
                };
                    
                PagamentoCartaoFactory.setCobrancasSelecionadas(cobrancas);
                
                showLoading();
                $state.go('home.pgtocartao-pagamento');
                    
            }

            async function obterDadosPagamento(parcela) {
                return await AcordoService.obterDadosPagarComCartao(parcela).then(function (retorno) {                                                            
                    $ionicLoading.hide();
                    return retorno;

                }).catch(function() {
                    $ionicLoading.hide();
                    Notification.error($translate.instant('General.ErrorNotSpecified'));
                });
            }

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-consulta/detail/acordo.consulta.pre.acordo.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
                backdropClickToClose: false
            }).then(function (modal) {
                acordoConCtrl.modalPreAcordo = modal;
            });

            $ionicModal.fromTemplateUrl('js/custom-components/acordo-consulta/detail/acordo.consulta.acordo.modal.html', {
                scope: $scope,
                animation: 'slide-in-up',
                backdropClickToClose: false
            }).then(function (modal) {
                acordoConCtrl.modal = modal;
            });

            acordoConCtrl.imprimir = function(codAcordo) {
            	dados = {
                    acordoOnLine: acordoConCtrl.adordo,
                    opcoesAcordo: acordoConCtrl.opcoesDoAcordo
                };           	
	  			
	        	let params = {};
				params.url = Configs.getApiServer() + '/financeiro/codAcordo/'+ dados.acordoOnLine.numAcordo +'/obterImagemDocumentoAcordo'; 
				params.method = "GET";
				
				DownloadArquivo.downloadArquivo(params);
            }
            
            acordoConCtrl.gerarRelatorioAcordo = function(codAcordo) {
              	let params = {};
	  			params.url = Configs.getApiServer() + '/financeiro/codPessoaLogada/'+ SessionData.user.id +'/codAluno/'+ encodeURI(SessionData.aluno.list[0].codAluno) +'/codAcordo/'+ codAcordo +'/exibirArquivo/false/geraRelatorioAcordo';
	  			params.method = "GET";
	  			
	  			DownloadArquivo.downloadArquivo(params);
            }

            $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
                if (acordoConCtrl.modal)
                    acordoConCtrl.modal.hide();
                if (acordoConCtrl.modalPreAcordo)
                    acordoConCtrl.modalPreAcordo.hide();
            });

            // AREA DO PIX
            $ionicModal.fromTemplateUrl('views/logged/cartao/modal.pagamento.pix.html', {
                scope: $scope,
                animation: 'slide-in-up', 
            }).then(function(modalPix) {
                acordoConCtrl.modalPix = modalPix;
            });

            acordoConCtrl.openModalPix = async function (parcela) {

                showLoading();
                let retorno = await obterDadosPagamento(parcela);

                let obj = {
                    candidato: null,
                    concurso: null,
                    listaDeCobrancas: [parcela.codCobranca],
                    valorTotal: retorno.cartaoValorDocumento
                }

                acordoConCtrl.modalPix.show();
                $scope.$broadcast('startPix', obj);
            }

            $scope.copiarPix = function() {
                if(!Clipboard.isSupported()){
                    Notification.warning($translate.instant('navegador.sem.suporte'));
                    return;
                }
                
                acordoConCtrl.clipboard = new Clipboard('#copy-button');
                Notification.success($translate.instant('pix.view.msg.copied'));                
            }

            $scope.closeModal = function() {
                acordoConCtrl.modalPix.hide()
            }
}]);