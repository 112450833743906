angular
    .module('MyApp')
    .service('EstouChegandoService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };

      this.listarAlunosPorPessoa = function() {
        let defer = $q.defer();
        var _objAluno = {
          codPessoa: SessionData.aluno.list[_aluno.selecionado].id
        };
        if (_objAluno) {
            $resource(Configs.getApiServer() + '/pessoas/:codPessoa/alunos', {}, {
                cache: false,
                query: {
                    method: 'GET',
                    isArray: true
                }
            })
            .query(_objAluno, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
        } else {
            defer.reject({ _msgErro });
        }

        return defer.promise;
    }

      this.localizacaoInstituicao = function(codAluno) {
        let defer = $q.defer();
        if (codAluno) {
            $resource(Configs.getApiServer() + '/pessoas/aluno/'+codAluno+'/localizacao-instituicao', {}, {
                cache: false,
                query: {
                    method: 'GET',
                }
            })
            .query(codAluno, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });
        } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;
      }
      
    this.contactarInstituicao = function(status) {
        let deferr = $q.defer();
        if (status) {
            $resource(Configs.getApiServer() + '/pessoas/contactar-instituicao',
                {}, { 'query': { method: 'POST' } }).query(status, function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }

        return deferr.promise;
    }

      
      
    }]);