angular.module('MyApp')
    .service('DownloadArquivo', ['$log', "$resource", "$q", "$http", "$ionicLoading", "FileSaver", "$translate",
    function ($log, $resource, $q, $http, $ionicLoading, FileSaver, $translate) {

        function insertParam(key, value) {
            var url = window.location.href;

            if (url.indexOf('?') > -1) {
                url += '&' + encodeURI(key) + '=' + encodeURI(value);
            } else {
                url += '?' + encodeURI(key) + '=' + encodeURI(value);
            }

            window.location.href = url;
        }

    	this.downloadArquivo = function(params) {

            insertParam('url_download', params.url);

            $http({
                url: params.url,
                method: params.method,
                data: params.data,
                params: params.params,
                responseType: "arraybuffer"
            }).then(success, error);

            function success(response) {
                $ionicLoading.hide();

            	if (response.status === 200) {
                    var fileName =  /.*filename=(.*)/g.exec(response.headers('content-disposition'))[1];

                    var type = 'application/pdf';
                    let pontoInsercao = fileName.lastIndexOf(".");
                    var extensao = fileName.substring(pontoInsercao, fileName.length);

                    if(!(params.alunoPublicacao == "" || params.alunoPublicacao == null || params.alunoPublicacao == undefined)){
                        fileName = fileName.substring(0, pontoInsercao) + " [ALUNO_" + params.alunoPublicacao + "]" + fileName.substring(pontoInsercao, fileName.length);
                    }

                    if((params.tipoArquivo == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      || params.tipoArquivo == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      || params.tipoArquivo == 'application/msword'
                      || params.tipoArquivo == 'application/excel')){
                      type = params.tipoArquivo;
                    } else{
                      switch (extensao) {
                        case '.docx':
                          type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                          break;
                        case '.xlsx':
                          type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                          break;
                        case '.word':
                          type = 'application/msword';
                          break;
                        case '.xls':
                          type = 'application/excel';
                          break;
                        default:
                          type = 'application/pdf';
                      }
                    }

                    var arquivo = new Blob([response.data], { type: type });
					          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && !(navigator.userAgent.indexOf('Safari') > -1);

                    if (!iOS) {
                        FileSaver.saveAs(arquivo, fileName);
                     }

            	}

                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);

                    window.location.href = window.location.href.replace(results[0], '');
                }
            }

            function error(response) {
                $ionicLoading.hide();

                if (window.location.href.indexOf('url_download') > -1) {
                    var regex = new RegExp('[?&]url_download(=([^#]*)|&|#|$)'),
                    results = regex.exec(window.location.href);

                    window.location.href = window.location.href.replace(results[0], '');
                }
            }
        }
    }
]);
