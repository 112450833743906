/**
 * Controller do componente Termo List (termo-list)
 */
(function () {
	angular.module('termoListModule').controller('TermoListComponentController', ['$scope', '$log', 'Notification', '$ionicModal', '$http', 'SessionData', '$ionicLoading', 'Configs', 'MatriculaService', 'DownloadArquivo', TermoListComponentController]);

	function TermoListComponentController($scope, $log, Notification, $ionicModal, $http, SessionData, $ionicLoading, Configs, matriculaService, downloadArquivo) {
		var ctrl = this;

		$log.debug('[TermoListComponentController]:ctrl=', ctrl);

		ctrl.sessionData = SessionData;
		ctrl.openTermoDetail = openTermoDetail;
		ctrl.closeTermoDetail = closeTermoDetail;
		ctrl.downloadTermo = downloadTermo;
		ctrl.getWindowWidth = getWindowWidth;
		ctrl.termos = []

		var alunos = ctrl.sessionData.aluno;
		ctrl.aluno = alunos.list[alunos.selecionado];

		let paramsListarTermos = {
			codPessoa: ctrl.sessionData.user.id
		}

		matriculaService.listarTermosLGPDAceitosPessoa(paramsListarTermos).then( result => {
			ctrl.termos = result;
		})

		function openTermoDetail(termoSelecionado) {
			let params = {idDocumentoPessoa: termoSelecionado};
			matriculaService.obterTermoLGPDAceitoPessoaImagem(params).then(result => {
				ctrl.termoAceito = decodeBase64(result ? result.html : '');
				ctrl.termoSelecionado = termoSelecionado;
				ctrl.modal.show();
			});
		}
		
		function closeTermoDetail() {
			ctrl.modal.hide();
		}

		function downloadTermo() {
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			
			let params = {};
			params.url = Configs.getApiServer() + '/pessoas/codPessoa/' + ctrl.sessionData.user.id + '/id_documento_pessoa/' + ctrl.termoSelecionado + '/download_documento_pessoa';
			params.method = "GET";
			
			downloadArquivo.downloadArquivo(params);
		}

		$ionicLoading.show({
			content: 'Loading',
			animation: 'fade-in',
			showBackdrop: true,
			maxWidth: 200,
			showDelay: 1
		});

		function getWindowWidth() {
			return window.innerWidth;
		}

		$ionicModal.fromTemplateUrl('js/custom-components/termo-detail/termo-modal.html', {
			scope: $scope,
			animation: 'slide-in-up',
		}).then(function (modal) {
			ctrl.modal = modal;
		});

		$scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
			if (ctrl.modal)
				ctrl.modal.hide();
		});

		function decodeBase64(base64) {
	
		  const text = atob(base64);
		  
		  if((text.indexOf('PT-BR') || text.indexOf('UTF-8') || text.indexOf('utf-8') || text.indexOf('utf8') || text.indexOf('pt-br')) > -1){
			return text;
		  } else {
			const length = text.length;
			const bytes = new Uint8Array(length);
			for (let i = 0; i < length; i++) {
				bytes[i] = text.charCodeAt(i);
			}
			const decoder = new TextDecoder(); // default is utf-8
			return decoder.decode(bytes);
		  }
		  
		}

	}
})();
