angular.module('MyApp')
    .service('CartaoService', ["Configs", "$resource", "$q", 'SessionData','DownloadArquivo', function (Configs, $resource, $q,SessionData, DownloadArquivo) {
        var aluno;
    	function dadosValidosAluno(){
    		if(SessionData.aluno){
    			aluno = {codPessoa: SessionData.user.id, codAluno: SessionData.aluno.list[SessionData.aluno.selecionado].codAluno};
    			return true;
    		}
    		return false;
    	}
        this.listarCobrancasParaPagamento = function(aluno) {
            let deferr = $q.defer();

            if (aluno) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoa/:codPessoa/codAluno/:codAluno/listarCobrancasParaPagamento').
                    query({
                        codPessoa: aluno.usuarioLogado.id,
                        codAluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }
        
        
        
        this.listaCobrancasBoletoCartao = function(aluno) {
            let deferr = $q.defer();
 
            if (aluno) {
                $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/cod_pessoa/:cod_pessoa/lista_cobrancas_boleto_cartao').
                    query({
                    	cod_pessoa: aluno.usuarioLogado.id,
                    	cod_aluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        	
        }
        
        

        this.calculaValoresTotaisPagamento = function(cobrancas) {
            let deferr = $q.defer();

            if (cobrancas && cobrancas.length > 0) {
                $resource(Configs.getApiServer() + '/financeiro/calculaValoresTotaisPagamento',
                    {}, { 'query': { method: 'POST' } }).query(cobrancas, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarFormaPagamento = function(calculos) {
            let deferr = $q.defer();

            if (calculos) {
                $resource(Configs.getApiServer() + '/financeiro/listarFormaPagamento',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(calculos, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarBandeirasCartao = function(cobrancas) {
            let deferr = $q.defer();

            if (cobrancas) {
                $resource(Configs.getApiServer() + '/financeiro/listarBandeirasCartao',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(cobrancas, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarMesesCartao = function() {
            let deferr = $q.defer();
            
            $resource(Configs.getApiServer() + '/financeiro/listarMesesCartao').query(function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

        this.listarAnosCartao = function() {
            let deferr = $q.defer();
            
            $resource(Configs.getApiServer() + '/financeiro/listarAnosCartao').query(function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

        this.pagarComCartaoCredito = function(params) {
            let deferr = $q.defer();

            if (params) {
                $resource(Configs.getApiServer() + '/financeiro/pagarComCartaoCredito',
                    {}, { 'query': { method: 'POST' } }).query(params, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarStatusPedido = function(aluno, numPedido) {
            let deferr = $q.defer();

            if (numPedido !== null) {
                $resource(Configs.getApiServer() + '/financeiro/aluno/:aluno/numPedido/:numPedido/listarStatusPedido',
                    {}, { 'query': { method: 'GET' } }).
                    query({
                        aluno: aluno,
                        numPedido: numPedido
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarPedidosPagamentoCartao = function(aluno, numPedido) {
            let deferr = $q.defer();

            if (numPedido !== null) {
                $resource(Configs.getApiServer() + '/financeiro/aluno/:aluno/numPedido/:numPedido/listarPedidosPagamentoCartao',
                    {}, { 'query': { method: 'GET', isArray: true } }).query({
                        aluno: aluno,	
                        numPedido: numPedido
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarCartoesPedido = function(aluno, numPedido) {
            let deferr = $q.defer();

            if (numPedido !== null) {
                $resource(Configs.getApiServer() + '/financeiro/aluno/:aluno/numPedido/:numPedido/listarCartoesPedido',
                    {}, { 'query': { method: 'GET', isArray: true } }).query({
                        aluno: aluno,
                        numPedido: numPedido
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarCobrancasPedido = function(aluno, numPedido) {
            let deferr = $q.defer();

            if (numPedido !== null) {
                $resource(Configs.getApiServer() + '/financeiro/aluno/:aluno/numPedido/:numPedido/listarCobrancasPedido',
                    {}, { 'query': { method: 'GET', isArray: true } }).query({
                        aluno: aluno,	
                        numPedido: numPedido
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarPedidosPagamento = function(user, aluno) {
            let deferr = $q.defer();

            if (user !== null && aluno !== null) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoaLogada/:codPessoa/aluno/:codAluno/listarPedidosPagamento',
                    {}, { 'query': { method: 'GET', isArray: true } }).query({
                        codPessoa: aluno.usuarioLogado.id,
                        codAluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }
        
        this.obterDadosPagarServicoCartao = function (params){
            let deferr = $q.defer();
            
            if (params){
                $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/id_boleto/:id_boleto/obtem_dados_pagar_servico_com_cartao',
                    {}, { 'query': { method: 'GET', isArray: false } }).query(params,
                    function (response) {
                        deferr.resolve(response);
                    }, function () {
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
            return deferr.promise;
        }
        
        
        this.listaCobrancasBoletoCartao = function(aluno) {
            let deferr = $q.defer();
 
            if (aluno) {
                $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/cod_pessoa/:cod_pessoa/lista_cobrancas_boleto_cartao').
                    query({
                    	cod_pessoa: aluno.usuarioLogado.id,
                    	cod_aluno: encodeURIComponent(aluno.codAluno)
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }
             return deferr.promise;
        	
        }
        
        
        this.obterConfiguracoesCartao = function(params){
        	let deferr = $q.defer();
            
            let aluno = {
                cod_aluno: params
            }
            
        	$resource(Configs.getApiServer() + '/financeiro/cod_aluno/:cod_aluno/obtem_config_cartao_de_credito',
        	        {}, {  'query':  {  method:  'GET'  } }).query(aluno, 
        	        function (result) {
        	          deferr.resolve(result);
        	        }, function (err) {
        	          deferr.reject(err);
        	        });
        	
        	return deferr.promise;
        }
                                
        this.downloadConsultaPagamento = function(aluno, numPedido) {
        	if (numPedido !== null) {
        		
	        	let params = {};
				params.url = Configs.getApiServer() + '/financeiro/aluno/' + aluno + '/numPedido/' + numPedido + '/consultarCartaoPedidoPdf'; 
				params.method = "GET";
				
				DownloadArquivo.downloadArquivo(params);				
        	}
        }

        /* ÁREA DE DÉBITOS AUTORIZADOS E CARTÕES SALVOS*/
        
        this.listarAutorizacoesDebito = function(debitoCartaoDto){
        	let deferr = $q.defer();
        	       
    		if (debitoCartaoDto) {
                $resource(Configs.getApiServer() + '/financeiro/lista_autorizacoes_debito',
                    {}, { 'query': { method: 'POST', isArray: true } }).query(debitoCartaoDto, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }		
        	        		
        	return deferr.promise;
        }

        this.listarCartoesSalvos = function(pessoa){
        	let deferr = $q.defer();
                               
    		if (pessoa) {
                $resource(Configs.getApiServer() + '/financeiro/codPessoa/:codPessoa/lista_cartoes_salvos',
                    {}, { 'query': { method: 'GET', isArray: true } }).query({
                    	codPessoa: encodeURIComponent(pessoa)
                    }, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }		
        	
        		
        	return deferr.promise;
        }

        this.desativarAutorizacao = function(idAutorizacao) {
            let deferr = $q.defer();

            if (idAutorizacao) {
                $resource(Configs.getApiServer() + '/financeiro/id_autorizacao_deb_cartao/' + idAutorizacao + '/desativa_autorizacao',
                {}, {  'query':  {  method:  'PUT'  } }).query({},
                function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.alterarCartaoProcesso = function(cartaoDadosDTO) {
            let deferr = $q.defer();

            if (cartaoDadosDTO) {
                $resource(Configs.getApiServer() + '/financeiro/altera_cartao_processo',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(cartaoDadosDTO, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        this.listarBandeiras = function() {
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/financeiro/listarBandeirasCartao',
                {}, {  'query':  {  method:  'GET'  } }).query({},
                function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

        this.listarProcessamentosDebitoCartao = function(params) {
            let deferr = $q.defer();

            $resource(Configs.getApiServer() + '/financeiro/listar_processamentos_debito_cartao/' + params,
                {}, {  'query':  {  method:  'GET', isArray: true  } }).query({},
                function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

        this.desativarCartao = function(params) {
            let deferr = $q.defer();
            
            $resource(Configs.getApiServer() + '/financeiro/id_cartao/' + params.idCartao + '/desativa_cartao_salvo',
                {}, {  'query':  {  method:  'PUT'  } }).query({},
                function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

        ///pix/configPix

        this.obtemInfoPix = function() {
            let deferr = $q.defer();
            
            if (dadosValidosAluno()) {
                $resource(Configs.getApiServer() + '/pix/codPessoaLogada/:codPessoa/codAluno/:codAluno/configPix',
                    {}, { 'query': { method: 'GET', isArray: false } }).query(aluno, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });    
            }      

            return deferr.promise;
        }

        this.gerarPix = function(inputPixDTO) {
            let deferr = $q.defer();

            if (inputPixDTO) {
                inputPixDTO.codPessoaLogada=SessionData.user.id;
                
                $resource(Configs.getApiServer() + '/pix/gerarPix',
                    {}, { 'query': { method: 'POST', isArray: false } }).query(inputPixDTO, function (response) {
                        deferr.resolve(response);
                    }, function(){
                        deferr.reject();
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }        

        this.criarRecorrenciaCartao = function(params) {
            let deferr = $q.defer();
            
            if (params) {
                $resource(Configs.getApiServer() + '/financeiro/criar-recorrencia-cartao',
                    {}, { 'query': { method: 'POST' } }).query(params, function (response) {
                        deferr.resolve(response);
                    }, function(err){
                        deferr.reject(err);
                    });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

        /* ÁREA DE PAGAMENTOS RECORRENTES*/

        this.listarPagamentosRecorrentes = function(params) {
            let deferr = $q.defer();
            
        	$resource(Configs.getApiServer() + '/financeiro/codAluno/:codAluno/listar-recorrencia-cartao',
        	        {}, {  'query':  {  method:  'GET', isArray: true  } }).query(params, 
        	        function (result) {
        	          deferr.resolve(result);
        	        }, function (err) {
        	          deferr.reject(err);
        	        });
        	
        	return deferr.promise;
        }

        this.listarParcelasPagamentoRecorrente = function(params) {
            let deferr = $q.defer();
            
        	$resource(Configs.getApiServer() + '/financeiro/idPgtoRecorrente/:idPgtoRecorrente/listar-parcelas-recorrencia-cartao',
        	        {}, {  'query':  {  method:  'GET', isArray: true  } }).query(params, 
        	        function (result) {
        	          deferr.resolve(result);
        	        }, function (err) {
        	          deferr.reject(err);
        	        });
        	
        	return deferr.promise;
        }

        this.cancelarRecorrenciaCartao = function(params) {            
            let deferr = $q.defer();
            
            $resource(Configs.getApiServer() + '/financeiro/id_pgto_recorrente/' + params.idPgtoRecorrente 
                                    + '/motivo/' + params.motivo
                                    + '/usuario/' + params.usuario + '/cancela_recorrencia_cartao',
                {}, {  'query':  {  method:  'PUT'  } }).query({},
                function (response) {
                    deferr.resolve(response);
                }, function(){
                    deferr.reject();
                });

            return deferr.promise;
        }

    }]);
