angular.module('MyApp').controller(
		'RecuperarSenhaController',
		[
				'$scope',
				'$state',
				'SessionData',
				'UserService',
				'$ionicLoading',
				'$http',
				'$stateParams',
				'Notification',
				'Storage',
				'$translate',
				'UtilService',
				function($scope, $state, SessionData,
						UserService, $ionicLoading, $http, $stateParams, Notification, Storage, $translate,UtilService) {
					function loading(value) {
						recuperarSenhaCtrl.loading = value;
						if (value)
							$ionicLoading.show({
								content : 'Loading',
								animation : 'fade-in',
								showBackdrop : true,
								maxWidth : 200,
								showDelay : 1
							});
						else
							$ionicLoading.hide();
					}
					$scope.login = {};
					
					$scope.objUser = angular.fromJson(Storage.get('_u'))					
					$scope.isResetSenha = true;
					$ionicLoading.hide();
					if ($scope.objUser){						
						if ($scope.objUser.senhaAlterada == false || $scope.objUser.senhaAlterada == 'false'){							
							$scope.isResetSenha = false;
						}
					}
					
					let path = location.origin + location.pathname;
					$scope.telaSolicitar = true;
					
					if ($stateParams.token !== undefined) {
						$scope.telaSolicitar = false;
					}
					
					$scope.validarSenhas = function(){
						var retorno = false;
						if($scope.login.senha !== undefined || $scope.login.senhaRepete !== undefined){
							if (angular.equals($scope.login.senha, $scope.login.senhaRepete)) {
								return true;
							}else{
								Notification.error("As senhas não são iguais!");
							}
						}else{
							Notification.error("Por favor, preencha os campos.");
						}
						return retorno;
					}

					$scope.validarRedefinicaoSenha = function(){
						var retorno = false;
						if($scope.login.senha !== undefined || $scope.login.senhaRepete !== undefined){
							if (angular.equals($scope.login.senha, $scope.login.senhaRepete)) {
								return true;
							}else{
								Notification.error("As senhas não são iguais!");
							}
						}else{
							Notification.error("Por favor, preencha os campos.");
						}
						return retorno;
					}
					
					$scope.voltar = function(){
						$state.go("login");
						SessionData.clear();
						Storage.clear();
						$state.go('login', {}, {reload: true});	
					}
					
					$scope.resetarSenha = function(){
						if($scope.validarSenhas()){
							$ionicLoading.show({
								content : 'Loading',
								animation : 'fade-in',
								showBackdrop : true,
								maxWidth : 200,
								showDelay : 1
							});
							UserService.resetarSenha($stateParams.token, $scope.login.senha, $scope.login.senhaRepete).then(function(result) {
								if(result.status == 412){
									Notification.error(result.mensagemDetalhada);
								}
								
								if(result.conteudo !== undefined && result.conteudo !== ""){
									Notification.success(result.conteudo);
									$state.go("login");
								}
								
								$ionicLoading.hide();
							}).catch(function(err) {
								Notification.error(err);
								$ionicHistory.nextViewOptions({
									disableBack: true
								});
								$ionicLoading.hide();
							});
						}
					}

					$scope.changePassword = function() {
						$ionicLoading.show({
						  content : 'Loading',
						  animation : 'fade-in',
						  showBackdrop : true,
						  maxWidth : 200,
						  showDelay : 0
						});
				
						var idPessoa = angular.fromJson(Storage.get('_u')).id;
						
						var user = {
						  oldPassword : encodeURIComponent($scope.login.senhaAtual),
						  newPassword : encodeURIComponent($scope.login.novaSenha),
						  newPasswordConfirmation : encodeURIComponent($scope.login.senhaConfirmacao),
						  idPessoa    : idPessoa,
						};
				
						$http({
						  method : 'PUT',
						  url : 'changePassword',
						  data : $.param(user),
						  headers : {
							'Content-Type' : 'application/x-www-form-urlencoded'
						  }
						}).success(changeSuccess).error(changeError);
				
						function changeSuccess(data, status, headers, config) {
						  if(status == 200){
									Notification.info($translate.instant('Home.view.passwordChanged'));
									if (SessionData.user){
										SessionData.user.senhaAlterada = "true";
									} else {
										SessionData.user = angular.fromJson(Storage.get('_u'));
										SessionData.user.senhaAlterada = "true";
										Storage.set("_u", JSON.stringify(SessionData.user));
									}
									
									
									$state.go("home.avisos");
						  } else {
									var error = status == 500 ? $translate.instant('Home.view.InvalidPassword') : data.message;
									Notification.error(error);													
						  }
				
						  $ionicLoading.hide();
						  cleanPasswordFields();
						}
				
						function changeError(data, status, headers, config) {
						  var error = status == 401 ? $translate.instant('Home.view.InvalidPassword') : data;
						  $ionicLoading.hide();
						}
				
						function cleanPasswordFields() {
						  $scope.login.oldPassword = "";
						  $scope.login.newPassword = "";
						  $scope.login.newPasswordConfirmation = "";						  
						}
					  }
					
					$scope.gerarToken = function(){
						if($scope.login.login !== undefined){
							$ionicLoading.show({
								content : 'Loading',
								animation : 'fade-in',
								showBackdrop : true,
								maxWidth : 200,
								showDelay : 1
							});
							UserService.gerarToken($scope.login.login, path).then(function(result) {
								Notification.success("Você receberá um email com dados para reset de senha!");
								$state.go("login");
								$ionicLoading.hide();
							}).catch(function(err) {
								Notification.error(err);
								$ionicHistory.nextViewOptions({
									disableBack: true
								});
								$ionicLoading.hide();
							});
						}else{
							Notification.error("Por favor, preencha o campo usuário.");
						}
					}

					$scope.logoLoginCustom = (function(){
						let params = {
							aplicacao: 'ALUNO_RESP',
							objeto: 'LOGO_LOGIN'
						}
			
						let defer = UtilService.obtemRecurso(params);
			
						return defer;
					})();

				} ]);
