(function () {
  /*
   * Modulo do componenet agenda-list-item (avisosList)
   */
  angular
    .module('agenda')
    .controller('AgendaListItemComponentController', [
      '$log',
      '$scope',
      AgendaListItemComponentController]);

  	function AgendaListItemComponentController($log, $scope) {
		var ctrl = this;

		//$log.debug('[AgendaListItemComponentController]\t$scope=', $scope, 'ctrl=', ctrl);
		ctrl.event.className = typeof(ctrl.event.className) == 'object' ? ctrl.event.className.join(' ') : ctrl.event.className;

    if(ctrl.event.end && ctrl.event.end!=null && ctrl.event.end != undefined){
      var [endData,endDataHora] = ctrl.event.end.split('T');
      var [endDataAno, endDataMes, endDataDia] = endData.split('-');
      var endData = [endDataDia, endDataMes, endDataAno].join('/');
      var endFormatMask = endData + ' - ' + endDataHora;
    }

    if(ctrl.event.start && ctrl.event.start != null && ctrl.event.start != undefined){
      var [startData,startDataHora] = ctrl.event.start.split('T');
      var [startDataAno, startDataMes, startDataDia] = startData.split('-');
      var startData = [startDataDia, startDataMes, startDataAno].join('/');
      var startFormatMask = startData + ' - ' + startDataHora;
    }

		ctrl.dataHora = (ctrl.event.allDay ? startData : startFormatMask);

		if ((endData || !ctrl.event.allDay) && endData!=null) {
			var horaEvento = ((endDataDia == startDataDia) ? endDataHora : endFormatMask);
			ctrl.dataHora += (horaEvento == "00:00" )|| (horaEvento == "23:59" ) ? "" : ' - ' + horaEvento;
		}

		ctrl.dataHora += ctrl.event.allDay ? ' ' : '';


      	$log.debug('[AgendaListItemComponentController]\tctrl=', ctrl);
   	}
})();
