angular
    .module('MyApp')
    .service('RequerentesService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.buscarRequerentes = function(idCaso){
        let deferr = $q.defer();
         if (idCaso) {
            $resource(Configs.getApiServer() + '/npj/requerentes-caso?idCaso=:idCaso',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  idCaso
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.buscarDocumentosRequerentes = function(idCasoRequerente){
        let deferr = $q.defer();
         if (idCasoRequerente) {
            $resource(Configs.getApiServer() + '/npj/documentos-requerente?idCasoRequerente=:idCasoRequerente',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  idCasoRequerente
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

    }]);
