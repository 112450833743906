angular.module('MyApp')

.factory('InterceptorFactory', ['$injector', '$q', 'HttpPendingRequestService', 'Configs',
  function($injector, $q, HttpPendingRequestService, AppCfg) {
    return {
        request: function(config) {
	        let $translate = $injector.get('$translate');

	        config.headers = config.headers || {};
	        config.headers = {...config.headers, 'accept-language-portal': $translate.use() };

	        if (config.url.indexOf('${API_URL}') > -1) {
                config.url = config.url.replace('${API_URL}', AppCfg.getApiServer());
	        }

            if (config.timeout === undefined) {
              config.timeout = HttpPendingRequestService.createTimeout();
            }

            return config;
        },

        responseError: function(response){

          let Notification = $injector.get('Notification');
          let $ionicLoading = $injector.get('$ionicLoading');
          let $translate = $injector.get('$translate');

          if (response.status < 200 || response.status > 299){

            if (response.status === -1 ){
              $ionicLoading.hide();
              /*
               * Request abortado via HttpPendingRequestService ou $http.config.timeout.
               * Nem success e nem failure callback devem ser invocados, assim, devolvemos
               * uma promise que nunca será resolvida.
               */
              return $q.defer().promise;
            }

            /*
             * Quando um erro HTTP ocorre, cancela os demais requests pendentes.
             */
            HttpPendingRequestService.cancelAll();
          }

          switch (response.status) {
            case 401:

              /*
               * Se há resposta do server, a mesma será tratada pelo chamador
               * e deve ser apenas rejeitada (e.g. LoginController).
               */
              if (response.data){
                return $q.reject(response);
              }

              var errorMsg = $translate.instant('General.SessionExpired');

              $ionicLoading.hide();
              Notification.error(errorMsg);

              let logoutService = $injector.get('LogoutService');
              logoutService.logout();

              break;
            case 500:
              if (response.config.responseType === "arraybuffer") {
                var enc = new TextDecoder("utf-8");
                var arr = new Uint8Array(response.data);
                response.data = JSON.parse(enc.decode(arr));
              }

              let message = $translate.instant("General.ErrorNotSpecified");
              if (response.data && (response.data.message || response.data.mensagemDetalhada)){
                  message = response.data.message || response.data.mensagemDetalhada;
              } 

              $ionicLoading.hide();

              if ( !((message.indexOf('JDBC') >= 0) || (message.indexOf('java') >= 0) || (message.indexOf('null') >= 0) || (message.indexOf('closed') >= 0)) ) {
                Notification.error(message);
              } else {
                Notification.error($translate.instant("General.ErrorNotSpecified"));
              }

              break;

              default: $ionicLoading.hide();
          }
          
          // Google Analytics
          if ( (ga !== undefined) && (ga !== null) ) {
            let message = $translate.instant("General.ErrorNotSpecified");
            if (response.data && (response.data.message || response.data.mensagemDetalhada)){
                message = response.data.message || response.data.mensagemDetalhada;
            }            

            var dataGoogleAnalytics = new Object();
            dataGoogleAnalytics.url = window.location.href.toString();
            dataGoogleAnalytics.navigator = navigator.userAgent;
            dataGoogleAnalytics.response = JSON.stringify(response);
            
            ga('send',
               'event',
               'Error ' + response.status,
               message,
               JSON.stringify(dataGoogleAnalytics)
            );
          }   

          return $q.reject(response);
        }
    }
}]);