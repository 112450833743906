angular
    .module('MyApp')
    .service('TarefasService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.buscarTarefas = function(idCaso){
        let deferr = $q.defer();
         if (idCaso) {
            $resource(Configs.getApiServer() + '/npj/tarefas-caso?idCaso=:idCaso',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  idCaso
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.incluirTarefa = function(tarefaDTO) {
        let deferr = $q.defer();
        if (tarefaDTO) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + "/npj/insere-tarefa-caso", {}, {
                  query: {
                          method: 'POST',
                          cache: false
                      }
              })
              .query(tarefaDTO, function(result) {
                  defer.resolve(result);
              }, function(err) {
                  defer.reject(err);
              });

          return defer.promise;
       } else {
           deferr.resolve(false);
       }
       return deferr.promise;
      }

      this.obterModelosDoc = function() {
        let deferr = $q.defer();
           $resource(Configs.getApiServer() + '/npj/obter-modelos-doc-tarefa',
               {}, { 'query': { method: 'GET', isArray: true } }).query({
               }, function (response) {
                 deferr.resolve(response);

               }, function(){
                   deferr.reject();
               });
       return deferr.promise;
      }

      this.uploadDocumentoPessoa = function(data) {
        let deferr = $q.defer();
        if (data) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + "/pessoas/upload_documento_pessoa", {}, {
                  query: {
                          method: 'POST',
                          cache: false
                      }
              })
              .query(data, function(result) {
                  defer.resolve(result);
              }, function(err) {
                  defer.reject(err);
              });

          return defer.promise;
       } else {
           deferr.resolve(false);
       }
       return deferr.promise;
      }

    }]);
