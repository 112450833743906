(function () {
    angular.module('card')
        .controller('CardComponentController', ['$log', '$scope', '$ionicScrollDelegate', '$state', 'TypeEnum', '$timeout', '$rootScope', 'RelatorioService',
            function ($log, $scope, $ionicScrollDelegate, $state, TypeEnum, $timeout, $rootScope, RelatorioService) {
                let vm = this;
                
                vm.stateIs = function(state){
                	return $state.is(state);
                }
                
                vm.checkedItems = function(){  
                	var selectedItems = [];
	                angular.forEach(vm.itens, function(value, index){
	        			if(value.checked){
	        				selectedItems.push(value);
	        			}
	        		});
	                return selectedItems;
                }
                
                vm.checkItem = function (item) {
                	$rootScope.$broadcast('CardCreditSelectionChanged');
                }

                vm.downloadRelatorio = function(item){                    
                    let params = {
                        grupoRelatorio: 'FinanceiroNG',
                        relatorio: 'CartaChargeback',
                        sistema: 'Lyceum',
                        parameterMap: {
                            p_id: item.chargeBack
                        }
                    }
                    RelatorioService.downloadReportServiceEP(params);
                }

                vm.getWindowWidth = function () {
                    return window.innerWidth;
                }

                vm.itemStyle = function () {
                    if (vm.cardItemStyle)
                        return 'item ' + vm.cardItemStyle;
                    return 'item';
                }

                vm.getValueOfType = function (item, column) {
                    switch (column.type) {
                        case TypeEnum.DATE:
                            return item[column.id] != null && item[column.id] != '' ? moment(item[column.id]).format('DD/MM/YYYY') : '';
                        case TypeEnum.STRING:
                            return item[column.id];
                        case TypeEnum.CURRENCY:
                            return typeof item[column.id] == 'number' ? convertToCurrency(item[column.id]) : item[column.id];
                        case TypeEnum.CARD:
                            return item[column.id];
                        default:
                            return item[column.id];
                    }
                }

                function convertToCurrency(input = 0) {
                    return parseFloat(input).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                }
            }
        ]
        );
})();
