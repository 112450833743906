(function() {
  angular.module('MyApp')
    .controller('LoginController', ['$rootScope', '$scope', 'sharedLoginService', '$timeout', '$ionicHistory','$http','Notification',
      function($rootScope, $scope, sharedLoginService, $timeout, $ionicHistory, $http, Notification) {

        var ensureClearState = function (event, data){
          $ionicHistory.clearCache();
          $ionicHistory.clearHistory();
          $scope.user = {};
          $scope.message = {};
          $scope.profile = {};
        };

        $scope.$on("$ionicView.beforeEnter", ensureClearState);

        $scope.cssLoginCustom = sharedLoginService.cssCustom();
        $scope.logoLoginCustom = sharedLoginService.logoLoginCustom();
        $scope.login = sharedLoginService.login;
        $rootScope.infiniteReached = function() { }
        $scope.selectProfile = sharedLoginService.selectProfile;
        $scope.selectProfile('aluno');

		exibeRecuperarSenhaRequest = function() {

			$http({
				method : 'GET',
				url : 'config-properties/exibe-recuperar-senha',
				headers : {}
			}).success(function (data, status, headers, config) {
				$scope.exibeRecuperarSenha = data;
			}).error(function(data, status, headers, config) {
				Notification.error('Problemas ao verificar as configurações do properties. Erro: ' + data);
			});
		}

		exibeRecuperarSenhaRequest();
		
		endRecuperarSenhaRequest = function() {

			$http({
				method : 'GET',
				url : 'config-properties/end-recuperar-senha',
				headers : {}
			}).success(function (data, status, headers, config) {
				$scope.endRecuperarSenha = data;
			}).error(function(data, status, headers, config) {
				Notification.error('Problemas ao verificar as configurações do properties. Erro: ' + data);
			});
		}

		endRecuperarSenhaRequest();
		
		publicKeyRequest = function() {

			$http({
				method : 'GET',
				url : 'config-properties/public-key',
				headers : {}
			}).success(function (data, status, headers, config) {
				$rootScope.publicKey = data;
			}).error(function(data, status, headers, config) {
				Notification.error('Problemas ao verificar as configurações do properties. Erro: ' + data);
			});
		}

		publicKeyRequest();
		
		recaptchaSiteKeyRequest = function() {
			$http({
				method : 'GET',
				url : 'config-properties/recaptcha-sitekey',
				headers : {}
			}).success(function (data, status, headers, config) {
				$scope.recaptchaSucesso = true;
				//verifica se deve exibir o recaptcha
				$rootScope.ativarRecaptcha = false;

				// se sim, obtem a chave
				if(data !== undefined && data !== '') {
					//usado para bloquear o botão submit
					$scope.recaptchaSucesso = false;

					//verifica se deve exibir o recaptcha
					$rootScope.ativarRecaptcha = true;

					//usado para desbloquear o botão submit
					recaptchaCallback = function () {
						document.getElementById("button-login").disabled = false;
					}
			
					recaptchaExpiredCallback = function () {
						document.getElementById("button-login").disabled = true;
					}

					//usado para gerar o captcha
					recaptchaRender = function () {
						$scope.recaptchaSucesso = false;
						grecaptcha.render( "g-recaptcha", {
							"sitekey"  : data,
							"callback" : recaptchaCallback,
							"expired-callback": recaptchaExpiredCallback
						});
					}
				} else {
					recaptchaRender = function () {};
				}
				
			}).error(function(data, status, headers, config) {
				Notification.error('Problemas ao verificar as configurações do properties. Erro: ' + data);
			});
		}

		recaptchaSiteKeyRequest();

		sharedLoginService.set($scope);
	  }
	  
    ]);
})(app);
