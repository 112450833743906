angular
    .module('MyApp')
    .service('AnexosService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.buscarAnexos = function(idCaso){
        let deferr = $q.defer();
         if (idCaso) {
            $resource(Configs.getApiServer() + '/npj/anexos-caso?idCaso=:idCaso',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  idCaso
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.uploadDocumento = function(params){
        let defer = $q.defer();

        $resource(Configs.getApiServer() + "/pessoas/upload_documento_pessoa/", {}, {
                query: {
                        method: 'POST',
                        cache: false
                    }
            })
            .query(params, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

        return defer.promise;
    }

    this.incluirAnexo = function(anexoDTO) {
      let deferr = $q.defer();
      if (anexoDTO) {
        let defer = $q.defer();
        $resource(Configs.getApiServer() + "/npj/insere-anexo-processo", {}, {
                query: {
                        method: 'POST',
                        cache: false
                    }
            })
            .query(anexoDTO, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

        return defer.promise;
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    }]);
