angular
    .module('MyApp')
    .service('AjusteBoletoService', ['$resource', 'DownloadArquivo', '$ionicLoading', '$q', 'SessionData', 'Configs', 
    	function($resource, DownloadArquivo, $ionicLoading, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
           
      this.getListaBoletosDoAluno = function() {
          let defer = $q.defer();
          
          var _objAluno = {
              codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
              codPessoa: SessionData.user.id
          };
          
          if (_objAluno) {
              $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:codAluno/cod_pessoa/:codPessoa/lista_boletos_do_aluno', {}, {
                  cache: false,                  
                  query: {
                      method: 'GET',
                      isArray: true
                  }
                  
                })
                .query(_objAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;          
      }
      
      this.getVerificaMensagemProrrogaBoleto = function(codBoleto) {
          let defer = $q.defer();
          
          var _objAluno = {
              codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
              codPessoa: SessionData.aluno.list[_aluno.selecionado].id,
              codBoleto: codBoleto
          };
          
          if (_objAluno) {
              $resource(Configs.getApiServer() + '/financeiro/cod_aluno/:codAluno/cod_pessoa/:codPessoa/cod_boleto_antigo/:codBoleto/verifica_mensagem_prorroga_boleto', {}, {
                  cache: false,
                  query: {
                      method: 'GET'
                  } 
                })
                .get(_objAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;          
      }
      
      this.getProrrogaVencimentoBoleto = function(codBoleto) {
          let defer = $q.defer();
          
          var _objAluno = {
              codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
              codPessoa: SessionData.aluno.list[_aluno.selecionado].id,
              codBoleto: codBoleto
          };
          
          if (_objAluno) {
              $resource(Configs.getApiServer() + '/financeiro/cod_boleto_antigo/:codBoleto/cod_aluno/:codAluno/prorroga_vencimento_boleto', {}, {
                  cache: false,
                  query: {
                      method: 'GET'
                  } 
                })
                .get(_objAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;          
      }
      
      // Download de arquivo pdf do Boleto
      this.downloadBoleto = function(codBoleto){
    	  	$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
    	  	
			let params = {};
			params.url = Configs.getApiServer() + '/financeiro/cod_aluno/' + encodeURI(SessionData.aluno.list[0].codAluno) + '/cod_boleto/' + codBoleto + '/exibir_arquivo/false/download_boleto'; 
			params.type = 'application/pdf';
			params.nomeArquivo = 'Boleto_' + codBoleto + '_' + new Date().getTime();
			params.method = "GET";
				
			DownloadArquivo.downloadArquivo(params);
		}
      
      
    }]);