/**
 * Componente Termo List (termo-list)
 */
(function(){
	angular.module('termoListModule').component('termoList', {
		templateUrl: 'js/custom-components/termo-list/termo-list.component.html',
		controller: 'TermoListComponentController',
		controllerAs: 'ctrl',
		bindings: {
			
		}
	});
})();