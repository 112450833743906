angular
    .module('MyApp')
    .service('CasosService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.listarCasos = function(codAluno){
        let deferr = $q.defer();
         if (codAluno) {
            $resource(Configs.getApiServer() + '/npj/casos?aluno=:codAluno',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  codAluno
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.editarCaso = function(casoDTO) {
        let deferr = $q.defer();
        if (casoDTO) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + "/npj/altera-caso", {}, {
                  query: {
                          method: 'POST',
                          cache: false
                      }
              })
              .query(casoDTO, function(result) {
                  defer.resolve(result);
              }, function(err) {
                  defer.reject(err);
              });

          return defer.promise;
       } else {
           deferr.resolve(false);
       }
       return deferr.promise;
      }



    }]);
