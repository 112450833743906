angular
    .module('MyApp')
    .service('MinhasTarefasService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

    this.listarTarefasAluno = function(codAluno){
      let deferr = $q.defer();
       if (codAluno) {
          $resource(Configs.getApiServer() + '/npj/tarefas-aluno?aluno=:codAluno',
              {}, { 'query': { method: 'GET', isArray: true } }).query({
                codAluno
              }, function (response) {
                deferr.resolve(response);

              }, function(){
                  deferr.reject();
              });
      } else {
          deferr.resolve(false);
      }
      return deferr.promise;
    }

    this.listarComentariosTarefa = function(idTarefa) {
      let deferr = $q.defer();
      if (idTarefa) {
         $resource(Configs.getApiServer() + '/npj/comentarios-tarefa?idCasoTarefa=:idTarefa',
             {}, { 'query': { method: 'GET', isArray: true } }).query({
              idTarefa
             }, function (response) {
               deferr.resolve(response);

             }, function(){
                 deferr.reject();
             });
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    this.incluirComentario = function(comentarioTarefa) {
      let deferr = $q.defer();
      if (comentarioTarefa) {
        let defer = $q.defer();
        $resource(Configs.getApiServer() + "/npj/insere-comentario-tarefa", {}, {
                query: {
                        method: 'POST',
                        cache: false
                    }
            })
            .query(comentarioTarefa, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

        return defer.promise;
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    this.excluirComentarioTarefa = function(idComentario) {
      const codAluno = SessionData.aluno.list[SessionData.aluno.selecionado].codAluno;
      let deferr = $q.defer();
      if (idComentario) {
         $resource(Configs.getApiServer() + '/npj/comentario/:idComentario/aluno/:codAluno',
             {}, { 'query': { method: 'DELETE', isArray: true } }).query({
              idComentario, codAluno
             }, function (response) {
               deferr.resolve(response);

             }, function(){
                 deferr.reject();
             });
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    this.uploadDocumentoPessoa = function(data) {
      let deferr = $q.defer();
      if (data) {
        let defer = $q.defer();
        $resource(Configs.getApiServer() + "/pessoas/upload_documento_pessoa", {}, {
                query: {
                        method: 'POST',
                        cache: false
                    }
            })
            .query(data, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

        return defer.promise;
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    this.incluirDocumentoPessoa = function(tarefaDTO) {
      let deferr = $q.defer();
      if (tarefaDTO) {
        let defer = $q.defer();
        $resource(Configs.getApiServer() + "/npj/altera-tarefa", {}, {
                query: {
                        method: 'POST',
                        cache: false
                    }
            })
            .query(tarefaDTO, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

        return defer.promise;
     } else {
         deferr.resolve(false);
     }
     return deferr.promise;
    }

    this.obterModelosDoc = function() {
      let deferr = $q.defer();
         $resource(Configs.getApiServer() + '/npj/obter-modelos-doc-tarefa',
             {}, { 'query': { method: 'GET', isArray: true } }).query({
             }, function (response) {
               deferr.resolve(response);

             }, function(){
                 deferr.reject();
             });
     return deferr.promise;
    }
    }]);


