var external_scope;

(function() {
  angular.module('login.service', ['aol.service', 'custom.services'])

  .service('SessionData', ['Storage', '$log', '$cookies',
    function(Storage, $log, $cookies){

      let srv = this;

      var aluno, alunosResponsavel, user, agenda, carrinho, perfil, data = {};

      this.create = function(){
        srv.aluno             = angular.fromJson(Storage.get('_a'));
        srv.alunosResponsavel = angular.fromJson(Storage.get('_alunosResponsavel'));
        srv.user              = angular.fromJson(Storage.get('_u'));
        srv.agenda            = angular.fromJson(Storage.get('_agenda'));
        srv.carrinho          = angular.fromJson(Storage.get('_carrinho'));
        srv.perfil            = Storage.get('perfil');
    
        $log.debug('typeof(agenda)=', typeof(srv.agenda), 'agenda=', srv.agenda);
    
        if (typeof(srv.carrinho) == 'undefined' || srv.carrinho == null) {
          srv.carrinho = [];
          Storage.set("_carrinho", JSON.stringify(srv.carrinho));
        }
    
        if (typeof(srv.agenda) == 'undefined' || srv.agenda == null) {
          srv.agenda = {
            'selectedDate' : moment()
          };
          Storage.set("_agenda", JSON.stringify(srv.agenda));
        }
        
        srv.data = {
          "aluno":             srv.aluno,
          "alunosResponsavel": srv.alunosResponsavel,
          "user":              srv.user,
          "agenda":            srv.agenda,
          "carrinho":          srv.carrinho,
          "perfil":            srv.perfil
        };
      }

      this.clear = function(){
        delete srv.data;
        delete srv.aluno;
        delete srv.alunosResponsavel;
        delete srv.user;
        delete srv.agenda;
        delete srv.carrinho;
        delete srv.perfil;

        // remove user-data cookie
        $cookies.remove('user-data');
      }

      this.isEmpty = function(field){
        if (!field){
          return true;
        }

        if ( srv[field] === undefined ){

          /*
           * Fallback para refresh feito pelo usuário no browser.
           * O ideal é substituir o 'SessionData' por 'Storage'.
           */

          srv.create();
        }

        $log.debug('type=', typeof(srv[field]), field + '=', srv[field]);
        return srv[field] === undefined || srv[field] === null || srv[field] === '' || angular.equals(srv[field], {});
      }

      this.setAluno = function (alunoSelecionadoResp){
        aluno = alunoSelecionadoResp;
      }
    }
  ])

  .factory('AlunoFromUser', ['$log', '$rootScope', 'Aluno.User', 'Aluno.AlunoTurmas', 'Notification', 'UserService', 'Storage', 
    function($log, $rootScope, Aluno_User, Aluno_AlunoTurmas, Notification, UserService, Storage) {
      return function(callback) {
        var User = angular.fromJson(Storage.get('_u'));

        external_User = User;
        if (!User) {
          $log.debug('[AlunoFromUser]\tUser not found.');
          return;
        }

        $log.debug('[AlunoFromUser]:User=', User);
        $log.debug('User.id=', User.id);

        var aluno = Aluno_User.get({userId: User.id}, Aluno_User_handleSuccess, Aluno_User_handleFailure);

        function Aluno_User_handleSuccess(result) {

          /*
           *  Se o aluno realizar login com a matrícula já será direcionado
           *  para a mesma sem exibir as outras matrículas para escolha. 
           */

          var aluno = result.content[result.content.map((a) => a.codAluno).indexOf(angular.fromJson(Storage.get('_u')).login)]; 

          if (aluno !== undefined){
            result.content = [];
            result.content.push(aluno);
          }

          UserService
            .buscarTransacoesAutorizadasAluno(result.content[0])
            .then(function(alunoNew) {
              result.content[0] = alunoNew;
              var a = {
                'list': result.content  ,
                'selecionado': 0
              };

              Storage.set("_a", JSON.stringify(a));
              Storage.set("_alunosResponsavel", JSON.stringify(a));
              callback();
            });
        }

        function Aluno_User_handleFailure() {
          $log.debug('[Aluno_User_handleFailure]\targuments=', arguments);
        }
      };
  }])
})();
