angular.module('MyApp')
    .service('AtividadesExtensaoService', ["Configs", "$resource", "$q" , "$http", function (Configs, $resource, $q, $http) {

        let methodGetObject= { cache: false, query: { method: 'GET', isArray: false } }
        let methodGetArray = { cache: false, query: { method: 'GET', isArray: true } }
        
        this.obterAtividadesExtensao = function(aluno = {}) {
            let deferr = $q.defer();

            let objQuery       = {};
            objQuery.idAluno   = aluno.codAluno;

            if (aluno.codAluno && aluno.id) {
                $resource(Configs.getApiServer() + '/matricula/listar-atividades-extensao', {}, methodGetObject)
                .query(objQuery, function (response) {
                    deferr.resolve(response);
                }, function (response) {
                    deferr.reject();
                });
            } else {
                deferr.resolve(false);
            }

            return deferr.promise;
        }

    }]);