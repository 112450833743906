angular
    .module('MyApp')
    .service('AndamentosService', ['$resource', '$q', 'SessionData', 'Configs', function($resource, $q, SessionData, Configs) {

      this.buscarAndamentos = function(idCaso){
        let deferr = $q.defer();
         if (idCaso) {
            $resource(Configs.getApiServer() + '/npj/andamentos-caso?idCaso=:idCaso',
                {}, { 'query': { method: 'GET', isArray: true } }).query({
                  idCaso
                }, function (response) {
                  deferr.resolve(response);

                }, function(){
                    deferr.reject();
                });
        } else {
            deferr.resolve(false);
        }
        return deferr.promise;
      }

      this.incluirAndamento = function(andamentoDTO) {
        let deferr = $q.defer();
        if (andamentoDTO) {
          let defer = $q.defer();
          $resource(Configs.getApiServer() + "/npj/insere-andamento", {}, {
                  query: {
                          method: 'POST',
                          cache: false
                      }
              })
              .query(andamentoDTO, function(result) {
                  defer.resolve(result);
              }, function(err) {
                  defer.reject(err);
              });

          return defer.promise;
       } else {
           deferr.resolve(false);
       }
       return deferr.promise;
      }

    }]);
