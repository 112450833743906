angular.
  module('aol.service')

  /*
   * ${API_URL} é substituido no InterceptorFactory por Configs.getApiServer()
   */

  // login
  .factory('Aluno.User', ['$resource',
	  function($resource) {
	    return $resource('${API_URL}/api/rest/alunos/user/:userId');
    }
  ])

  .factory('Aluno', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId', {}, {
    	query: {
          method: 'GET',
          //params: {alunoId: 'alunos'},
          isArray: true
        }
      });
    }
  ])

  // Foto do Aluno
  .factory('Aluno.Foto', ['$resource',
     function($resource){
  	   return $resource('${API_URL}/api/rest/alunos/codPessoa/:codPessoa/foto', {}, {
	       query: {
	  	     method: 'GET'
	       }
         }
       );
  	 }
  ])

  // Aluno > Boletim Completo
  .factory('Aluno.Boletins', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId/pessoa/:pessoaId/boletins', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Aluno > Disciplinas Boletim
  .factory('Aluno.Disciplinas.Boletim', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId/pessoa/:pessoaId/disciplinasBoletim', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])
  
  // Aluno > Provas > Boletim
  .factory('Aluno.Provas.Boletim', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/Prova/aluno/:codAluno/disciplina/:codDisciplina/turma/:codTurma/ano/:ano/periodo/:periodo/provasBoletim', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
        }
      });
    }
  ])
  
  // .factory('Aluno.Boletim.Id', ['$resource',
  //   function($resource) {
  //     return $resource('${API_URL}/api/rest/alunos/:alunoId/boletim/id', {}, {
  //       query: {
  //         method: 'GET',
  //         params: {page: 0, size: 1000},
  //         //isArray: true
  //       }
  //     });
  //   }
  // ])

  // Aluno > Turma
  .factory('Aluno.AlunoTurmas', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId/alunoTurmas', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Aluno > Turma Disciplinas
  .factory('Aluno.TurmaDisciplinas', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId/turmas/:turmaId/turmaDisciplinas', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Aluno > Horários Aula
  .factory('Aluno.HorariosAula', ['$resource',
    function($resource) {					// /codPessoa/{codPessoa}/codAluno/{codAluno}/dia/{diaSemana}/HorariosAula
      return $resource('${API_URL}/api/rest/alunos/codPessoa/:codPessoa/codAluno/:codAluno/dia/:diaSemana/HorariosAula', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

//'/api/rest/alunos/:alunoId/boletim/:boletimId/disciplinas'
  // .factory('Aluno.Boletim.Disciplinas', ['$resource',
  //   function($resource) {
  //     return $resource('${API_URL}/api/rest/alunos/:alunoId/BoletimDisciplinas', {}, {
  //       query: {
  //         method: 'GET',
  //         params: {page: 0, size: 1000},
  //         //isArray: true
  //       }
  //     });
  //   }
  // ])

  // Aluno > Calendário
  .factory('Aluno.Calendario.Aulas', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/:alunoId/calendario/:calendarioId/aulas', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Aluno > Agenda
  .factory('Event.Aluno', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/pessoa/:codPessoa/events', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Aluno > Avisos
  .factory('Aluno.Avisos', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/codPessoa/:codPessoa/codAluno/:codAluno/AlunoAvisos', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])
  
  // Aluno > Ocorrências
  .factory('Aluno.Ocorrencias', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/codPessoa/:codPessoa/codAluno/:codAluno/AlunoOcorrencias', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])
  
  // Aluno > Ocorrências > Submeter ciência
  .factory('Aluno.Ocorrencias.Ciencia', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/codPessoa/:codPessoa/codAluno/:codAluno/AlunoOcorrencias/submeterCiencia', {}, {
    	query: {
          method: 'POST',
          params: {page: 0, size: 1000},
        }
      });
    }
  ])

  // Secretaria > Servicos
  .factory('Aluno.Secretaria.Servicos', ['$resource',
    function($resource) {
      return $resource('${API_URL}/api/rest/alunos/pessoa/:codPessoa/:codAluno/Servicos', {}, {
        query: {
          method: 'GET',
          params: {page: 0, size: 1000},
          //isArray: true
        }
      });
    }
  ])

  // Financeiro > Boletos
  .factory('Financeiro.Boletos', ['$resource',
	  function($resource){
		return $resource('${API_URL}/api/rest/financeiro/codAluno/:codAluno/Boletos?codPessoa=:codPessoa', {}, {
			query: {
				method: 'GET',
				params: {page: 0, size: 1000},
				//isArray: true
			}
		})
	}])
	
	// Financeiro > Boletos > Download
	.factory('Financeiro.Boletos.Download', ['$resource',
	  function($resource){								  // /codAluno/{codAluno}/codBoleto/{codBoleto}/BoletoDownload
		return $resource('${API_URL}/api/rest/financeiro/codAluno/:codAluno/codBoleto/:codBoleto/BoletoDownload', {}, {
			query: {
				method: 'GET',
				responseType:'application/pdf'
			}
		})
	}])
	
  // Aluno > Minha Turma
  .factory('Aluno.Minha.Turma', ['$resource',
	  function($resource){
		return $resource('${API_URL}/api/rest/MinhaTurma/:codAluno/AlunoMinhaTurma', {}, {
			query: {
				method: 'GET',
				params: {page: 0, size: 1000},
				//isArray: true
			}
		})
	}])	

  // Aluno > Frequencia
  .factory('Aluno.Frequencia', ['$resource',
	  function($resource){
		return $resource('${API_URL}/api/rest/alunos/codAluno/:codAluno/codPessoa/:codPessoa/Frequencias', {}, {
			query: {
				method: 'GET',
				params: {page: 0, size: 1000},
				//isArray: true
			}
		})
	}])
	
  // Aluno > Tarefas
  .factory('Aluno.Tarefas', ['$resource',
	  function($resource){
		return $resource('${API_URL}/api/rest/tarefas/listByTarefasAluno/:idAluno', {}, {
			query: {
				method: 'GET',
				params: {page: 0, size: 1000},
				//isArray: true
			}
		})
  }])
  
  // Aluno > Avaliação Institucional 
  .factory('Aluno.AvaliacaoInstitucional', ['$resource', 
	  function($resource){ 
		return $resource('${API_URL}/avaliacaoInstitucional/questionarios/questionariosPendentesAluno?aluno=:codAluno', {}, { 
			query: { 
				method: 'GET', 
				params: {page: 0, size: 1000}, 
				isArray: true 
			} 
		}) 
  }])
	
  // Financeiro > Cobranca
  .factory('Financeiro.Cobranca', ['$resource',
	  function($resource){
		return $resource('${API_URL}/api/rest/Cobranca/listByAluno/:codAluno/:codPessoa', {}, {
			query: {
				method: 'GET',
				params: {page: 0, size: 1000},
				//isArray: true
			}
		})
  }])
  
   
  .service('agendaEventService', function() {
    var event = {};
  
    var setEvent = function(newObj) { event = newObj; };
    var getEvent = function(){ return event; };
  
    return { setEvent: setEvent, getEvent: getEvent};
  });