(function() {
    angular.module('wizard')
    .controller('WizardComponentController', ['$log', '$scope', 'Storage', 'AonlineUtils', '$ionicScrollDelegate', '$state', '$timeout', '$ionicModal', 'MatriculaService', 'SessionData', 
        function($log, $scope, Storage, AonlineUtils, $ionicScrollDelegate, $state, $timeout, $ionicModal, MatriculaService, SessionData) {
        let vm = this;

        vm.currentStep = 0;
        vm.maxSteps = vm.steps.length-1;
        let alunoSelecionado = SessionData.aluno.list[SessionData.aluno.selecionado];

        $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
            var step = vm.steps.find(function(value) {
                return value.state == toState.name;
            })

            if(step) vm.currentStep = vm.steps.indexOf(step);
            else vm.currentStep = 0;
        });

        function adjustScroll() {
            let instances = $ionicScrollDelegate._instances;
            
            let scrollInstance = instances.filter(function(inst) {
                if(inst.$element[0].id == 'wizardScrollContent')
                    return inst;
            });
            
            if(scrollInstance.length > 0) {
                scrollInstance[0].resize();
                scrollInstance[0].scrollTop();
            }
        }

        vm.onButtonCancelClick = function(){
            if(vm.cancelButton.onClick) {
            	try{         	
	                vm.cancelButton.onClick().then(function(){
	                    vm.currentStep--;
	                    vm.additionalButton = {};
	                    $state.go(vm.steps[vm.currentStep].state);
	                    adjustScroll();
	                });
                }catch(e){
                	
                }
            } else {
                vm.currentStep--;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
                adjustScroll();
            }
        }

        $ionicModal.fromTemplateUrl('views/logged/matricula/modal.confirmacao.matricula.html', {
            scope: $scope,
            animation: 'slide-in-up',            
            hardwareBackButtonClose: false,
            backdropClickToClose: false
        }).then(function(modalConfirmacao) {
            vm.modalConfirmacao = modalConfirmacao;
        });

        $ionicModal.fromTemplateUrl('views/logged/matricula/modal.confirmacao.autorizacao.html', {
            scope: $scope,
            animation: 'slide-in-up',            
            hardwareBackButtonClose: false,
            backdropClickToClose: false
        }).then(function(modalAutorizacao) {
            vm.modalAutorizacao = modalAutorizacao;
        });

        
        vm.confirmarAutorizacoesMatricula = function() {            
            let listaAutorizacoes = JSON.parse(Storage.get("listaAutMatricula"));
            
            let autorizacaoMatriculaDTO = listaAutorizacoes;
            
            AonlineUtils.showLoading(true);
            
            MatriculaService.confirmarAutorizacao(autorizacaoMatriculaDTO).then((result) => {
                Storage.set("realizouConfirmacaoDeAutorizacao", false);
                AonlineUtils.showLoading(false);                
                if(vm.currentStep == vm.steps.length-1) {
                    Storage.set("realizouConfirmacaoDeAutorizacao", true);
                    $state.reload();

                } else {
                    vm.currentStep++;
                    vm.additionalButton = {};
                    
                    $state.go(vm.steps[vm.currentStep].state);
                    adjustScroll();
                }

                vm.modalAutorizacao.hide();
            }, (err) => {
                AonlineUtils.showLoading(false);
            })
        }

        vm.cancelarAutorizacao = function() {
            vm.modalAutorizacao.hide();
        }

        vm.modalConfirmacaoShow = function() {
            // recarrega o modal de confirmacao se necessário
            if (!vm.modalConfirmacao) {
                $ionicModal.fromTemplateUrl('views/logged/matricula/modal.confirmacao.matricula.html', {
                    scope: $scope,
                    animation: 'slide-in-up',
                    hardwareBackButtonClose: false,
                    backdropClickToClose: false
                }).then(function(modalConfirmacao) {
                    vm.modalConfirmacao = modalConfirmacao;
                    vm.modalConfirmacao.show();
                });
            }
            else {
                vm.modalConfirmacao.show();
            }
        }

        vm.confirmarMatricula = function() {

            let params = {
                codAluno: alunoSelecionado.codAluno,
                disciplinasList: JSON.parse(Storage.get("disciplinasMatriculadas"))
            };
            
            AonlineUtils.showLoading(true);

            MatriculaService.confirmarMatricula(params).then((result) => {
                // limpa modal de confirmação, para que seja recarregado
                // para evitar bug onde os botoes não funcionam até que o wizard seja recarregado
                vm.modalConfirmacao = null;
                Storage.set("disciplinasMatriculadas", null);
                AonlineUtils.showLoading(false);
                vm.currentStep++;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
                adjustScroll();
                
            }, (err) => {
                AonlineUtils.showLoading(false);
            })
        }

        vm.cancelarConfirmacao = function() {
            vm.modalConfirmacao.hide();
        }
        
        vm.onButtonNextClick = async function(){
            if(vm.nextButton.onClick){
            	vm.nextButton.onClick().then(function(){
                    if (vm.steps[vm.currentStep].state == "home.matricula.disciplinas"){
                        vm.modalConfirmacaoShow();
                        return;
                    } else if (vm.steps[vm.currentStep].state == "home.matricula.autorizacao") {
                        vm.modalAutorizacao.show();
                        return;
                    }
                    vm.currentStep++;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                    adjustScroll();
                });
            } else {
                vm.currentStep++;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
                adjustScroll();
            }
        }

        vm.onButtonFinalClick = function(){
            if(vm.finalButton.onClick) {
                vm.finalButton.onClick().then(function(){
                    if (vm.steps[vm.currentStep].state == "home.matricula.autorizacao") {
                        vm.modalAutorizacao.show();
                        return;
                    }
                    vm.currentStep = 0;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                });
            } else {
                vm.currentStep = 0;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
            }
        }
        
        vm.onButtonPaymentClick = function(){
            if(vm.paymentButton.onClick) {
                vm.paymentButton.onClick().then(function(){
                    vm.currentStep = 0;
                    vm.additionalButton = {};
                    $state.go(vm.steps[vm.currentStep].state);
                });
            } else {
                vm.currentStep = 0;
                vm.additionalButton = {};
                $state.go(vm.steps[vm.currentStep].state);
            }
        }

        if(vm.steps[vm.currentStep])
            $state.go(vm.steps[vm.currentStep].state);

        vm.contentStyle = function() {
            let style = vm.style;

            if(vm.fixButton)
                style += ' margin-bottom: 60px; ';

            return style;
        }

        vm.parentScope = $scope.$parent;
        vm.parentScope.adjustWizardScroll = adjustScroll;
	}]);
})();
