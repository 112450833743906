angular
    .module('MyApp')
    .service('FinanceiroService', ['$resource', 'DownloadArquivo', '$ionicLoading', '$q', 'SessionData', 'Configs', 
    	function($resource, DownloadArquivo, $ionicLoading, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
           
      this.listarNotasFiscaisEletronicas = function listarNotasFiscaisEletronicas() {
          let defer = $q.defer();
          
          var _objAluno = {
              codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
              codPessoa: SessionData.aluno.list[_aluno.selecionado].id
          };
          
          if (_objAluno) {
              $resource(Configs.getApiServer() + '/financeiro/codPessoa/:codPessoa/codAluno/:codAluno/listar-notas-fiscais-eletronicas', {}, {
                  cache: false,                  
                  query: {
                      method: 'GET',
                      isArray: true
                  }
                  
                })
                .query(_objAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
          } else {
            defer.reject({ _msgErro });
        }
        return defer.promise;          
      }


      this.obterLinkNota = function(idCobrancaNF) {
        let defer = $q.defer();
        
        var _nota = {
            idCobrancaNF: idCobrancaNF,
        };
        
        if (_nota) {
            $resource(Configs.getApiServer() + '/financeiro/:idCobrancaNF/obter-link-nota', {}, {
                cache: false,                  
                query: {
                    method: 'GET',
                    isArray: false
                }
                
              })
              .query(_nota, function(result) {
                  defer.resolve(result);
              }, function(err) {
              defer.reject(err);
          });
        } else {
          defer.reject({ _msgErro });
      }
      return defer.promise;          
    }

}]);