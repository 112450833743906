angular.module('MyApp').controller(
'ObterBoletoTokenController',
[
'$scope',
'$location',
'$ionicLoading',
'CobrancaService',
'UtilService',
function($scope, $location, $ionicLoading, CobrancaService, UtilService) {

	
	function downloadBoleto(){
		let params= $location.search();
		$scope.token=params.token;
		$scope.candidato=params.candidato;

		if($scope.token && $scope.token){
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});

			if( $scope.candidato && $scope.candidato === 'S'){
				CobrancaService.downloadBoletoCandidatoToken($scope.token);
			}else{
				CobrancaService.downloadBoletoAlunoToken($scope.token);
			}
		}
	}

	downloadBoleto();
	$scope.downloadBoletoLink=function($event){
		$event.stopPropagation();
		downloadBoleto();
	}

	$scope.logoLoginCustom = (function(){
		let params = {
			aplicacao: 'ALUNO_RESP',
			objeto: 'LOGO_LOGIN'
		}

		let defer = UtilService.obtemRecurso(params);

		return defer;
	})();
	
	
	
}]);
