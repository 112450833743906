angular.module('MyApp')

.factory('sharedLoginService', [
	'$log',
	'$http',
	'$location',
	'$rootScope',
	'$window',
	'$state',
	'$translate',
	'Notification',
	'$ionicLoading',
	'$timeout',
	'$stateParams',
	'AlunoFromUser',
	'$ionicModal',
	'UserService',
	'Storage',
	'SessionData',
	'Configs',
	'Aluno.AvaliacaoInstitucional',
	'appModalService', 
	'MatriculaService',
	'Storage',
	'UtilService',
	'serviceAlunos',
	'$cookies',
	function($log, $http, $location, $rootScope, $window, $state, $translate,
			 Notification, $ionicLoading, $timeout, $stateParams, AlunoFromUser,
			 $ionicModal, UserService, Storage, SessionData, Configs,
       Aluno_AvaliacaoInstitucional, appModalService, MatriculaService, Storage, UtilService, serviceAlunos, $cookies) {

		var dataset = {};
		var methods = {};

		methods.cssCustom = function(){
			let params = {
				aplicacao: 'ALUNO_RESP',
				objeto: 'CSS_LOGIN'
			}

			let defer = UtilService.obtemRecurso(params);

			return defer;
		}

		methods.logoLoginCustom = function(){
			let params = {
				aplicacao: 'ALUNO_RESP',
				objeto: 'LOGO_LOGIN'
			}

			let defer = UtilService.obtemRecurso(params);

			return defer;
		}

	    methods.set = function (data) {
				dataset = data;
			}

	    methods.get = function get() {
				return dataset;
			}
			
		async function publicKeyRequest() {
            return await $http({
				method : 'GET',
				url : 'config-properties/public-key',
				headers : {}
			}).then(function(data){
				return data.data; 
			});
		}

		methods.validCaptcha = function () {
		  	var capresp = grecaptcha.getResponse();
		  	return capresp != null && capresp.length > 0;
		}

	    methods.login = async function() {
	    	
	    	$ionicLoading.show({
	    		content : 'Loading',
	    		animation : 'fade-in',
	    		showBackdrop : true,
	    		maxWidth : 200,
	    		showDelay : 0
	    	});

	    	//dataset.message? dataset.message.error = undefined : undefined;

        if(window.hostApp) {

		  if($rootScope.ativarRecaptcha && !Storage.get("autoLogin") && !methods.validCaptcha()){
	    	$ionicLoading.hide();
		  	Notification.error("Captcha inválido!");
			return;
		  }

			//usado para o caso do autologin não preencher a key
		  if($rootScope.publicKey === undefined){
			$rootScope.publicKey = await publicKeyRequest();
		  }
		  let publicKey = $rootScope.publicKey;
		  let encrypt = new JSEncrypt();
		  encrypt.setPublicKey(publicKey);
		  let signature = encrypt.encrypt(dataset.user.password);
		  
		  let recaptchaResponse = "";
		  if($rootScope.ativarRecaptcha) {
			recaptchaResponse = grecaptcha.getResponse();
		  }
		  
		  // cria nova variavel para não afetar as variaveis do front end
		  credencialEncriptada = {username:dataset.user.username, password:signature, recaptchaResponse:recaptchaResponse};

          $http({
            method : 'POST',
            url : 'auth',
            data : methods.serializeData(credencialEncriptada),
            headers : {
              'Content-Type' : 'application/x-www-form-urlencoded'
            }
          }).success(methods.handleSuccess).error(methods.handleError);
        }
        else {
          $ionicLoading.hide();
          Notification.error("HostApp is required!");
        }
      }

	    methods.handleSuccess = function (data, status, headers, config) {
	    	var self= this;

            /*
             * User data carregado via cookie criado no backend.
             */
            var base64UserCookie = $cookies.get('user-data');
            Storage.set("_u", window.atob(base64UserCookie));

    		AlunoFromUser(function() {
        	self.listaAlunos = angular.fromJson(Storage.get('_a'));

        	/*
        	 * Verifica quantidade de alunos e define o perfil do usuário: Responsável ou Aluno
        	 */
       		if(self.listaAlunos.list.length > 1){
       			self.alunosResponsavel = self.listaAlunos;
   				  Storage.set("perfil", "responsavel");
       		}
       		else {
       			self.aluno = self.listaAlunos;
       			Storage.set("perfil", "aluno");
			    }

       		SessionData.create();

			if(SessionData) 
				SessionData.aluno.list[0].permittedPages = SessionData.aluno.list[0].transacoes;

       		/*
       		 * Tenta carregar página inicial definida para o aluno
       		 */
			if (Storage.get("autoLogin")){
				var landingState = Storage.get("autoLogin");
				var state;

				angular.forEach($state.get(), function(value) {
					if($state.href(value.name) === landingState){
						state = value.name;
					}
				});
				
				landingState = state;
			} else {
				var defaultLandingState  = methods.get().url || 'home.avisos';
			}

       		let selAluno = self.listaAlunos.list[self.listaAlunos.selecionado];

       		UserService.getPaginaInicial(selAluno).then( function(result) {
						param = {
							"aluno": selAluno.codAluno,
							"sessao": null
						}
						serviceAlunos.geraLogConexao(param).then(function(result){
							Storage.set("sessaoLogConexoes", result.sessao);
						})

    					if(result != undefined && result.paginaRedirecionamento !== null && result.paginaRedirecionamento !== '') {
    					  landingState = result.paginaRedirecionamento;

    					  if(landingState){

    						/*
    						 * Converte url relativa (e.g. '/home/avisos') para respectivo
    						 * state (e.g. 'home.avisos') quando necessário.
    						 */

    						landingState = landingState.replace(new RegExp('/', 'g'), '.');

    						if (landingState.indexOf('.') === 0) {
    						  landingState = landingState.substring(1);
    						}

    						$log.debug('Página inicial alterada:', landingState);
    					  }
    					}
  				})
  				.catch(function(err){
  					$log.error('Erro no serviço "Página Inicial":', err);
  				})
  				.then(function(){								
					
					// Verify if Contract mus be accept
					let promise1 = MatriculaService.verificaExibeContratoLogin(selAluno.codAluno).then(function success(result) {
						Storage.set("exibirContrato", result.valor);
														
						if (!result.valor){
							$rootScope.isContractNotRequired = true;
						}	

						}, function error(result) {
							let errorMsg;
							if (result && result.mensagemDetalhada) {
								errorMsg = result.mensagemDetalhada;
							} else {
								errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
							}
							
							Notification.error(errorMsg);
					});
					

					let paramsLgpd = {
						codPessoa: selAluno.usuarioLogado.id,
						codAluno: selAluno.codAluno,
						codResp: "null", //TODO Mudar para o id do responsável quando o mesmo estiver retornando da API
						sistema: "AOnlineResp"
					}

					// Verify if lgpd Contract mus be accept
					let promise2 = MatriculaService.verificaExibeContratoLGPD(paramsLgpd).then(function success(result) {
												
						Storage.set("_contratoLgpd", JSON.stringify(result));
						
						if ((!result) || (result.necessita_aceite_lgpd && result.necessita_aceite_lgpd.toUpperCase() == "N")){
							$rootScope.isLgpdNotRequired = true;
						} 	
						
					}, function error(result) {
						let errorMsg;
						if (result && result.mensagemDetalhada) {
							errorMsg = result.mensagemDetalhada;
						} else {
							errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
						}
						
						Notification.error(errorMsg);
					})
					Promise.all([promise1, promise2]).then( () => {
						if ($rootScope.isContractNotRequired && $rootScope.isLgpdNotRequired) {
							methods.verificarAvaliacaoInstitucional(selAluno);
						}
						$state.go(landingState || defaultLandingState);
					}, () => {
						$state.go(landingState || defaultLandingState);
					});


    			})
    		});
	    }

	    methods.handleError = function handleError(data, status, headers, config) {
        var errorMsg = data;

		//reseta recaptcha em caso de senha incorreta/limite de tentativas
		if($rootScope.ativarRecaptcha) {
			grecaptcha.reset();
		}

        switch (status) {
          case 401:
			  if(errorMsg === undefined || errorMsg === '') {
              	errorMsg = $translate.instant('Login.view.invalidPassword');
			  }
              break;
          case 403:
              errorMsg = $translate.instant('Login.view.accessDenied', { value: data});
              break;
          case 500:
              errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
              break;
        }

	    	$ionicLoading.hide();
	    	Notification.error(errorMsg);
        return;
	    }

	    /**
	     * Transforma o objeto 'data' em uma encoded String com pares
	     * chave/valor prontos para uso em URIs.
	     */
	    methods.serializeData = function (data) {
			if(!angular.isObject(data)) {
			  return ((data === null) ? "" : data.toString());
			}

			var buffer = [];

			for( var name in data) {
				if(!data.hasOwnProperty(name)) {
					continue;
				}

				var value = data[name];
				buffer.push(encodeURIComponent(name) + "=" + encodeURIComponent((value === null) ? "" : value));
			}

			var source = buffer.join("&").replace(/%20/g, "+");

			return (source);
		}

	    methods.selectProfile = function (profile) {
	    	let classPositive    = 'button button-positive';
	        let classOutline     = 'button button-positive button-outline';
	        let loginAluno       = 'Login.view.AlunoUsername';
	        let loginResponsavel = 'Login.view.ResponsavelUsername';

	        dataset.loginAlunoButton       = 'Login.view.AlunoButtonLabel';
	        dataset.loginResponsavelButton = 'Login.view.ResponsavelButtonLabel';

	        if (profile == 'aluno') {
	        	dataset.profile = {};

	        	dataset.profile.name = 'aluno';
	        	dataset.buttonClassAluno = classPositive;
	        	dataset.buttonClassResponsavel = classOutline;
	        	dataset.loginUsername = loginAluno;
	        }

	        if (profile == 'responsavel') {
	        	dataset.profile = {};

	        	dataset.profile.name = 'responsavel';
	        	dataset.buttonClassAluno = classOutline;
	        	dataset.buttonClassResponsavel = classPositive;
	        	dataset.loginUsername = loginResponsavel;
	        }
	    }

    	/**
    	 * Verifica se existe questionários pendente para o aluno responder e o direciona para a avaliação institucional.
    	 */
	    methods.verificarAvaliacaoInstitucional = function verificarAvaliacaoInstitucional (aluno) {
				Aluno_AvaliacaoInstitucional.query({codAluno: encodeURIComponent(aluno.codAluno)}, handleSuccess, handleFailure);
								
				// Tratamento de sucesso da chamada ao serviço REST
		    function handleSuccess(result){
		    	if (result.length > 0 && SessionData.aluno.list[0].transacoes.indexOf('avaliacaoinstitucional') > -1) {							
							if (result.find(function(item){ return item.OBRIGATORIA === 'S'; })){
									Storage.set('questionariosObrigatorios', true);									
									return;
							}

							// Define as configurações do modal.
							$ionicModal.fromTemplateUrl('./views/logged/_avaliacao.institucional.view.html', {
								scope : $rootScope,
								animation : 'slide-in-up',
								hardwareBackButtonClose: false,
								backdropClickToClose: false
							}).then(function(modal) {
									$rootScope.modalAvaliacao = modal;
									$timeout(function(){
											modal.show();
									}, 500)
                	
							});						
														
		    	}
		    }

		    // Tratamento de erro da Chamada ao serviço REST
		    function handleFailure(reason){

		        var keys = Object.keys(reason);
		        var str = '';
		        for (var k in keys) {
		          str += reason[k] + '\n';
		        }

		        Notification.error('Problemas ao verificar a avaliação institucional. Erro: ' + str);
		    }
	    }

	    return methods;
	}
]);
