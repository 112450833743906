angular.module('MyApp')
    .service('ServicoService', ["Configs", "SessionData", "$resource", "$q", function (Configs, SessionData, $resource, $q) {
        
        var _msgErro = {
            msgErro: 'Error student undefined'
        };

        var dadosAluno = {
            codAluno: SessionData.aluno.list[SessionData.aluno.selecionado].codAluno,
			codPessoa: SessionData.aluno.list[SessionData.aluno.selecionado].id
        }

         this.listarServicos = function() {
            let defer = $q.defer();
            var _aluno = SessionData.aluno;
            var codPessoa =  {"codPessoaLogada": SessionData.user.id}
            if(_aluno){
                codPessoa["codAluno"]= _aluno.list[_aluno.selecionado].codAluno;
            }

            if (_aluno) {
                $resource(Configs.getApiServer() + '/pessoas/listar_servicos_solicitaveis', {}, {
                        query: {
                            method: 'POST',
                            isArray: true
                        }
                    })
                    .query(codPessoa, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({
                    _msgErro
                });
            }
            return defer.promise;
        }
        
        this.obtemDocumentoCertificacao = function(codSolicitacao, codItemSolicitacao){
            let defer = $q.defer();
            
            let params = {
                "codSolicitacao": codSolicitacao,
                "codItemSolicitacao": codItemSolicitacao,
                "codPessoa": SessionData.user.id
            };
            
            $resource(Configs.getApiServer() + '/pessoas/codSolicitacao/:codSolicitacao/codItemSolicitacao/:codItemSolicitacao/codPessoa/:codPessoa/obtemDocumentoCertificacao', {}, {
                    query: {
                            method: 'GET',
                            cache: false
                        }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }
        
        this.listarCamposServico = function(servico) {
          let defer = $q.defer();
          var dadosServico = {"codAluno": SessionData.aluno.list[SessionData.aluno.selecionado].codAluno, "codServico": servico.servico};

          $resource(Configs.getApiServer() + '/pessoas/obtem_servico_solicitavel', {}, {
            query: {
                method: 'POST',
                isArray: true
            }
          })
          .query(dadosServico, function(result) {
              defer.resolve(result);
          }, function(err) {
              defer.reject(err);
          });
          return defer.promise;
        }
        
        this.listarOpcoesCampo = function(codServico, parametro) {
          let defer = $q.defer();
          var dadosServico = {'codAluno': SessionData.aluno.list[_aluno.selecionado].codAluno, "codServico": codServico, "parametro": parametro};

          $resource(Configs.getApiServer() + '/pessoas/obtem_combo_parametro', {}, {
            query: {
                method: 'POST',
                isArray: true
            }
          })
          .query(dadosServico, function(result) {
              defer.resolve(result);
          }, function(err) {
              defer.reject(err);
          });
          return defer.promise;
        }
        
        this.listarServicosSolicitados = function(codPessoa, codAluno) {
            let defer = $q.defer();
            let params = {
                "codPessoa ": codPessoa,
                "codAluno ": codAluno
            };

            $resource(Configs.getApiServer() + '/pessoas/:codPessoa/alunos/:codAluno/servicos-solicitados', {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }

        this.listarServicosSolicitadosCancelamento = function() {
            let defer = $q.defer();
            
            $resource(Configs.getApiServer() + '/pessoas/cod_aluno/:codAluno/cod_pessoa/:codPessoa/lista_servicos_solicitados_cancelamento', {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })
                .query(dadosAluno, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }

        this.cancelarSolicitacaoServico = function(aluno, codSolicitacao, codItemSolicitacao) {
            let defer = $q.defer();
            let params = {
                aluno: aluno,	
                codSolicitacao: codSolicitacao,
                codItemSolicitacao: codItemSolicitacao
            };

            $resource(Configs.getApiServer() + '/pessoas/aluno/:aluno/cod_solicitacao/:codSolicitacao/cod_item/:codItemSolicitacao/cancela_solicitacao_servicos', {}, {
                    cache: false,
                    query: {
                        method: 'GET'
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }
                
        this.obterAndamentoSolicitacaoServico = function(aluno, codSolicitacao, codItem) {
            let defer = $q.defer();
            let params = {
                "aluno": aluno,	
                "cod_solicitacao": codSolicitacao,
                "cod_item": codItem
            };

            $resource(Configs.getApiServer() + '/pessoas/aluno/:aluno/cod_solicitacao/:cod_solicitacao/cod_item/:cod_item/obtem_andamento_solicitacao_servico', {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }

        this.obterParametroServicoSolicitado = function(codAluno, codSolicitacao, codItemSolicitacao, codServico) {
            let defer = $q.defer();
            let params = {
                "codSolicitacao": codSolicitacao,
                "codItemSolicitacao": codItemSolicitacao,
                'codAluno': codAluno,
                "codServico": codServico
            };


            $resource(Configs.getApiServer() + '/pessoas/lista_parametros_servico_solicitado', {}, {
                    query: {
                        method: 'POST',
                        isArray: true
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }
        
        this.obterAndamentoAnexo = function(aluno, codAndamentoSolicitacao) {

            let defer = $q.defer();
            let params = {
                "aluno": aluno,
                "cod_andamento": codAndamentoSolicitacao,
            };

            $resource(Configs.getApiServer() + '/pessoas/aluno/:aluno/cod_andamento/:cod_andamento/lista_andamento_anexo_visivel_aluno', {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }

        this.obterPermitePagarServicoCartao = function(boleto, codAluno) {
            let defer = $q.defer();
            let params = {
                "boleto": boleto,
                'codAluno': codAluno
            };


            $resource(Configs.getApiServer() + '/financeiro/permite_pagar_servico_com_cartao', {}, {
                    query: {
                        method: 'POST'
                    }
                })
                .query(params, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    defer.reject(err);
                });

            return defer.promise;
        }        
        
        this.downloadBoleto = function(params) {
            let defer = $q.defer();
                $resource(Configs.getApiServer() + '/financeiro/codAluno/:codAluno/codBoleto/:codBoleto/downloadBoleto', {}, {
                        cache: false,
                        get: {
                            responseType: 'arraybuffer',
                            transformResponse: function(data) {
                                if (status == 500) {
                                    defer.reject();
                                    var decoder = new TextDecoder("utf-8");
                                    var domString = decoder.decode(data);
                                    var json = JSON.parse(domString);
                                    return json
                                }

                                let pdf;
                                if (data) {
                                    pdf = new Blob([data], {
                                        type: 'application/pdf'
                                    });
                                }
                                return {
                                    pdf: pdf
                                };
                            }
                        }
                    })
                    .get(params, function(result) {
                        defer.resolve(result);
                    }, function(error) {
                        defer.reject(error);
                    });
            return defer.promise;
        }
    }]);